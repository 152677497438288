import React, { useState } from "react";
import styled from "@emotion/styled";

import { Box, Grid, Typography, Card, CardMedia, CardContent } from "@mui/material";

import SimpleResponsivePicture from "./SimpleResponsivePicture";
import PlaceHolderImage from "../assets/placeholder2.webp";

export default function TeamMemberCard({ showRole = true, ...props }) {
  const [ImageObj, setImageObj] = useState(
    props?.person?.Image?.data ? props?.person?.Image?.data[0]?.attributes : {}
  );
  const [ObjectPosition, setObjectPosition] = useState(
    props?.person?.options?.imageSx["object-position"] || "center"
  );

  const handleOnMouseEnter = () => {
    if (props?.person?.Image?.data) {
      setImageObj(props?.person?.Image?.data[1]?.attributes);
      // setObjectPosition(props?.person?.options?.imageSx2["object-position"]);
    }
  };
  const handleOnMouseLeave = () => {
    if (props?.person?.Image?.data) {
      setImageObj(props?.person?.Image?.data[0]?.attributes);
      // setObjectPosition(props?.person?.options?.imageSx["object-position"]);
    }
  };
  const handleOnTouchStart = () => {
    if (props?.person?.Image?.data) {
      setImageObj(props?.person?.Image?.data[1]?.attributes);
      // setObjectPosition(props?.person?.options?.imageSx["object-position"]);
    }
  };
  const handleOnTouchEnd = e => {
    if (props?.person?.Image?.data) {
      setImageObj(props?.person?.Image?.data[0]?.attributes);
      // setObjectPosition(props?.person?.options?.imageSx["object-position"]);
    }
  };

  return (
    <Card sx={{ maxWidth: { xs: "calc(100dvw - 2rem)", md: 290 } }}>
      <CardMedia
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onTouchStart={handleOnTouchStart}
        onTouchEnd={handleOnTouchEnd}
      >
        {props?.person?.Image?.data && (
          <SimpleResponsivePicture
            imageObj={ImageObj}
            height={"100%"}
            objectPosition={"center top"}
          />
        )}
        {!props?.person?.Image?.data && (
          <SimpleResponsivePicture
            imageObj={{ url: PlaceHolderImage }}
            alt="Placeholder"
            // width="290px"
            // height="auto"
            objectPosition={"center top"}
          />
          // <img src={PlaceHolderImage} alt="Placeholder" width="290px" height="auto" />
        )}
      </CardMedia>

      {showRole && (
        <CardContent sx={{ backgroundColor: "#f5f5f5" }}>
          <Typography align="center">
            <strong>{props?.person?.Name}</strong>
          </Typography>
          <Typography align="center">{props?.person?.Role}</Typography>
        </CardContent>
      )}
    </Card>
  );
}
