import React from "react";
import styled from "@emotion/styled";

import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
} from "@mui/material";

import { parseWithLinks, removeCommentAttributes } from "../Functions";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Accordian({ Items, ...props }) {
  const [expanded, setExpanded] = React.useState();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const wrapperStyle = removeCommentAttributes(props?.options?.wrapperStyle) || {};
  const defaultExpanded = removeCommentAttributes(props?.options?.defaultExpanded) || false;
  const disableGutters = removeCommentAttributes(props?.options?.disableGutters) || true;
  const panelBackgroundColor = removeCommentAttributes(props?.options?.panelBackgroundColor) || "";
  const panelTextColor = removeCommentAttributes(props?.options?.panelTextColor) || "";

  return (
    <Box component={Container} sx={{ ...wrapperStyle?.sx }}>
      {props?.Title && (
        <Box bgcolor="common.white">
          {props?.Title && (
            <Typography variant="h2" align="center">
              {props?.Title}
            </Typography>
          )}
        </Box>
      )}
      {Items.map((item, index) => {
        const imageFormats = item?.graphic?.data?.attributes?.formats || {};

        const srcset = Object.keys(imageFormats)
          .map(format => {
            const url = imageFormats[format]?.url;
            const width = imageFormats[format]?.width;
            return `${url} ${width}w`;
          })
          .join(", ");

        if (item.role === "Chestermere") {
          return false;
        }

        return (
          <Accordion
            key={item.Name}
            disableGutters={disableGutters}
            // TODO
            // expanded={
            //   expanded === `panel${index}` && !defaultExpanded ? expanded === `panel${index}` : true
            // }
            // onChange={!defaultExpanded ? handleChange(`panel${index}`) : null}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ boxShadow: 0, borderBottom: ".5px solid rgba(0,0,0,.12)" }}
            defaultExpanded={defaultExpanded}
          >
            <AccordionSummary
              id={item.Name}
              expandIcon={<ExpandMoreIcon fontSize="large" color="primary" />}
              aria-controls="panel-content"
            >
              <Typography sx={{ textTransform: "uppercase", fontWeight: 700 }} color="primary">
                {item.Name}
              </Typography>
            </AccordionSummary>
            {item.ExpandedText && (
              <AccordionDetails sx={{ backgroundColor: panelBackgroundColor }}>
                {item?.graphic?.data && (
                  <Box
                    component="img"
                    src={`${
                      imageFormats?.xsmall?.url ||
                      imageFormats?.small?.url ||
                      item?.graphic?.data?.attributes?.url
                    }`}
                    srcSet={srcset}
                    sizes={`285px`}
                    id={item.Name}
                    //TODO setup a styled component to handle breakpoints

                    sx={{
                      width: { xs: "100%", md: 300 },
                      height: "auto",
                      //  maxHeight,
                      objectFit: "cover",
                      objectPosition: "center",
                      marginBottom: "3rem",
                    }}
                    // style={{ width, height, objectFit, ...style }}
                    alt={item?.graphic?.data?.attributes?.alternativeText}
                  />
                )}
                {parseWithLinks(item.ExpandedText, {
                  alignText: "left",
                  color: panelTextColor,
                })}
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </Box>
  );
}
