import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Box, Typography } from "@mui/material";
import placeholder from "../assets/placeholder.webp";
export default function ContactFooter() {
  return (
    <Box
      component={Grid}
      direction={{ xs: "column-reverse", md: "column" }}
      justifyContent={"flex-end"}
      container
      sx={{
        position: "relative",
        background: {
          xs: "#474C55",
          md: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(71,76,85,1) 50%, rgba(71,76,85,1) 100%)",
        },
        height: { xs: 600, md: 500 },
        width: "100%",
        marginTop: { xs: 0, md: "-8rem" },
      }}
    >
      <FooterImage src={placeholder}></FooterImage>{" "}
      <Box m={10}>
        <Typography variant="body1" sx={{ textTransform: "uppercase" }} color={"white"}>
          <strong>Building concierge and security</strong>
        </Typography>
        <Typography variant="body1" color={"white"}>
          P: (403) 263-7156
          <br />
          F: (403) 263-7101
        </Typography>
      </Box>
    </Box>
  );
}
const FooterImage = styled("img")`
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
  ${props => props.theme.breakpoints.up("sm")} {
    height: auto;
    width: 80%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  ${props => props.theme.breakpoints.up("md")} {
    height: 500px;
    width: auto;
    position: absolute;
    right: 0;
  }
`;
