import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Box } from "@mui/material";

import TeamMemberCard from "./TeamMemberCard";
import { parseWithLinks } from "../Functions";
//TODO update CMS to include a bio section
export default function TeamMembers(props) {
  const leadership = props?.team_members?.data?.filter(person => person?.attributes?.Bio);
  const regular = props?.team_members?.data?.filter(person => !person?.attributes?.Bio);

  // Swap the last two team members
  let temp = leadership[leadership.length - 1];
  leadership[leadership.length - 1] = leadership[leadership.length - 2];
  leadership[leadership.length - 2] = temp;

  return (
    <>
      <Box component={Typography} variant="h1" fullWidth align="center" sx={{ mt: "4rem" }}>
        Leadership Team
      </Box>
      <Grid
        container
        justifyContent="center"
        align="center"
        sx={{ margin: "5rem 0" }}
        columnGap={8}
      >
        {leadership?.map((person, index) => {
          return (
            <Grid
              key={person.attributes.Name}
              container
              item
              xs={12} // Full width on extra-small screens
              sm={6} // Two columns on small screens (6 spans half of 12)
              md={4} // Two columns on medium screens
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignContent={"center"}
              alignItems={"center"}
              mt={".5rem"}
              columnGap={{ xs: 4, sm: 0.5, md: 0 }}
            >
              <TeamMemberCard person={person.attributes} showRole={false} />

              <TitleWrapper variant="h3" align="left" mb={0}>
                {person.attributes.Name}
              </TitleWrapper>
              <Typography align="left" paragraph>
                {person.attributes.Role}
              </Typography>
              <Box
                variant="body1"
                align="left"
                sx={{ paddingBottom: "5rem", ml: { xs: "1rem" }, mr: { xs: "1rem" } }}
              >
                {parseWithLinks(person?.attributes?.Bio, { alignText: "left" })}
              </Box>
            </Grid>
          );
        })}
        <Grid
          container
          justifyContent="center"
          align="center"
          sx={{ margin: "5rem 0" }}
          id="Member Wrapper"
          rowGap={5}
          columnGap={{ xs: 4, sm: 2, md: 0 }}
        >
          {/* Regular Team */}
          {regular.map((person, index) => {
            //Skips the Leadership group
            // if (
            //   person.attributes.Role === "President/Principal" ||
            //   person.attributes.Role === "Vice President/Principal" ||
            //   person.attributes.Name === "Brandie Ferguson" ||
            //   person.attributes.Role === "Director of Property Management" ||
            //   person.attributes.Role === "President/Broker"
            // ) {
            //   return;
            // }
            return (
              <Grid key={person.attributes.Name} item xs={12} sm={5} md={3}>
                <TeamMemberCard person={person.attributes} />
              </Grid>
            );
          })}
          {/* return (
          <Grid item xs={12} md={3} sx={{ marginTop: "7rem" }}>
            <TeamMemberCard person={person.attributes} />
          </Grid>
        ); */}

          {/* <Grid
            item
            container
            xs={12}
            md={6}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <BobbleHeadImage
              src="https://cms.riverparkproperties.ca/uploads/Bobbleheads_Group_7604bbb4a3.jpg"
              alt="Bobbbleheads"
            />
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}
const BobbleHeadImage = styled("img")`
  width: 100%;
  height: auto;
  padding: 3rem 0;
  ${props => props.theme.breakpoints.up("md")} {
    padding: 3rem;
  }
`;
const TitleWrapper = styled(Typography)`
  &:after {
    content: url(https://cms.riverparkproperties.ca/uploads/Leaf_988d3514cd.svg);
    margin-left: 1rem;
  }
`;
