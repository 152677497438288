import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import purify from "dompurify";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import ResponsivePicture from "./ResponsivePicture";
import StrapiGrid from "./StrapiGrid";
import StrapiGridItemDanger from "./StrapiGridItemDanger";
import { addClassToLinksInParagraphs } from "../Functions";

export default function TwoColumn(props) {
  const leftBlockStyle = props?.options?.leftBlockStyle || {};
  const rightBlockStyle = props?.options?.rightBlockStyle || {};
  const layout = props?.options.layout || {};
  const leftSx = leftBlockStyle?.innerStyles || {};
  const rightSx = rightBlockStyle?.innerStyles || {};
  //
  let LeftColumnCopy = props?.LeftColumn;
  // if (layout?.useButtonForLink) {
  //   LeftColumnCopy = addClassToLinksInParagraphs(LeftColumnCopy, "button", "_blank");
  // }
  let RightColumnCopy = props?.RightColumn;
  // if (layout?.useButtonForLink) {
  //   RightColumnCopy = addClassToLinksInParagraphs(RightColumnCopy, "button", "_blank");
  // }

  return (
    <StrapiGrid options={props?.options?.layout}>
      {props?.options?.layout?.title && (
        <Typography variant="h2" align="left" style={{ whiteSpace: { xs: "pre", sm: "unset" } }}>
          {props?.options?.layout?.title}
        </Typography>
      )}
      <Grid direction="row" container justifyContent={"space-between"}>
        <StrapiGridItemDanger
          textBlockStyle={leftBlockStyle}
          Copy={LeftColumnCopy}
          sx={leftSx}
          useButtonForLink={layout?.useButtonForLink}
        />

        <StrapiGridItemDanger
          textBlockStyle={rightBlockStyle}
          Copy={RightColumnCopy}
          sx={rightSx}
          useButtonForLink={layout?.useButtonForLink}
        />
      </Grid>
    </StrapiGrid>
  );
}
