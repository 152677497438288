import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import StrapiGrid from "./StrapiGrid";
import NewsCard from "./NewsCard";

export default function NewsSection({ handleNewsClick, newsData, theme, ...props }) {
  const currentLocation = useLocation();

  function sortByDate(a, b) {
    return new Date(b.PublishDate) - new Date(a.PublishDate);
  }

  return (
    <Box
      sx={{ ...props?.options?.wrapperStyle?.sx }}
      // p={{ xs: "8rem 2rem" }}
      id="news-section"
    >
      <Typography variant="h2" align="center">
        Recent News
      </Typography>
      <StrapiGrid
        options={{
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          // columns: 3.2,
          columnSpacing: "",
          columnGap: 0.1,
          rowSpacing: 1,
          spacing: "",
          wrap: "wrap",
          sx: { padding: "2rem 0 0", borderTop: "2px solid rgba(16,72,100,.25)" },
        }}
      >
        {newsData?.sort(sortByDate).map((item, index) => {
          if (currentLocation.pathname === "/home" && index > 2) {
            return;
          }
          return <NewsCard key={index} handleNewsClick={handleNewsClick} theme={theme} {...item} />;
        })}
      </StrapiGrid>
    </Box>
  );
}
