export function validateForm(formElements, validationErrors, setValidationErrors, setSending) {
  // let hasAtLeastOneCheckmark =
  //   formElements["group[260][1]"].checked ||
  //   formElements["group[260][2]"].checked ||
  //   formElements["group[260][3]"].checked ||
  //   formElements["group[260][4]"].checked ||
  //   formElements["group[260][5]"].checked ||
  //   formElements["group[260][6]"].checked;
  let hasErrors = false;
  const validationObject = {};
  const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim;

  if (formElements.EMAIL.value.search(regex) === -1) {
    // The result can be accessed through the `m`-variable.
    // setValidationErrors({ ...validationErrors, EMAIL: "This is not a valid email" });
    validationObject.EMAIL = "This is not a valid email";
    // setValidationErrors(prev => {
    //   console.log("prev", prev);
    //   const newState = { ...prev, EMAIL: "This is not a valid email" };
    //   console.log("newState", newState);
    //   return newState;
    // });

    setSending(false);
    hasErrors = true;
  }

  if (formElements.EMAIL.value.search(regex) !== -1) {
    // The result can be accessed through the `m`-variable.

    setValidationErrors({ ...validationErrors, EMAIL: null });
  }

  // if (formElements.RES_TYPE.value === "") {
  // The result can be accessed through the `m`-variable.
  // setValidationErrors(() => ({ ...validationErrors, RES_TYPE: "Choose one..." }));
  //   validationObject.RES_TYPE = "Choose one...";
  //   setSending(false);
  //   hasErrors = true;
  // }

  // if (formElements.RES_TYPE.value !== "") {
  // The result can be accessed through the `m`-variable.
  //   setValidationErrors({ ...validationErrors, RES_TYPE: null });
  // }
  // if (!hasAtLeastOneCheckmark) {
  //   // setValidationErrors(() => ({ ...validationErrors, HOMETYPE: "Choose one..." }));
  //   validationObject.HOMETYPE = "Choose one...";
  //   setSending(false);
  //   hasErrors = true;
  // }
  // if (hasAtLeastOneCheckmark) {
  //   setValidationErrors({ ...validationErrors, HOMETYPE: null });
  // }

  if (hasErrors) {
    console.log(validationObject);
    setValidationErrors(validationObject);
    return false;
  }
  setValidationErrors({});
  //   Object.keys(validationErrors).forEach(function (index) {
  //     console.log(validationErrors[index]);
  //     validationErrors[index] = null;
  //   });
  return true;
}
