import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
export default function ImageGroup(props) {
  // TODO implement options from CMS

  // {
  //     "layout": {},
  //     "imageStyle": {},
  //     "wrapperStyle": {},
  //     "textBlockStyle": {}
  //   }
  const { justifyContent, alignContent, columnGap, wrap, ...layoutOptions } =
    props?.options?.layout || false;

  const wrapperStyle = props?.options?.wrapperStyle || {};
  const imageStyle = props?.options?.imageStyle || {};
  const textBlockStyle = props?.options?.textBlockStyle || {};

  return (
    <Grid
      id="address-group"
      container
      justifyContent={justifyContent || "center"}
      alignContent={alignContent || "flex-start"}
      columnGap={columnGap || "5%"}
      rowGap={"2rem"}
      wrap={wrap || "wrap"}
      columns={props?.ImageGroupImage?.length} //TODO This should have a sensible max value
      {...layoutOptions}
    >
      {props?.item &&
        props?.item?.map((member, index) => {
          return (
            <Grid
              key={member?.graphic.data.attributes.url}
              xs={layoutOptions?.item?.xs || 12}
              sm={layoutOptions?.item?.sm || 5}
              md={layoutOptions?.item?.md || 2.3}
              lg={layoutOptions?.item?.lg || "auto"}
              xl={layoutOptions?.item?.xl || "auto"}
              item
            >
              <Grid container direction={"column"}>
                <SimpleResponsivePicture
                  imageObj={
                    member?.graphic?.data?.attributes || member?.graphic?.data?.attributes?.url
                  }
                  sx={{ ...imageStyle }}
                  objectFit={"contain"}
                />

                {member?.copy && (
                  <Grid item align="center" dangerouslySetInnerHTML={{ __html: member?.copy }} />
                )}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}
