import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import SimpleResponsivePicture from "../SimpleResponsivePicture";
import StrapiGridItemDanger from "../StrapiGridItemDanger";
export default function MatrixGroup({ Image, Copy, bgcolor, direction, theme, isEven, ...props }) {
  // TODO
  // Because the image is not getting a width and height from parent elements
  // the responsive image is defaulting to the largest image size.
  // Here I am reducing the options out of the available.
  const SimplifiedImage = {
    formats: {
      imageText1X: { ...Image.formats.imageText1X },
      imageText2X: { ...Image.formats.imageText2X },
      medium: { ...Image.formats.medium },
    },
  };
  delete Image.formats;
  Image.formats = SimplifiedImage.formats;

  const baseHeight = 390;
  const height = { xs: 330, sm: 330, md: baseHeight, lg: baseHeight, xl: baseHeight };
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      direction={{
        xs: `column${isEven ? direction : ""}`,
        sm: `column${direction}`,
        md: `column${direction}`,
      }}
      sx={{ position: "relative" }}
    >
      <MatrixImage item container height={height}>
        <SimpleResponsivePicture imageObj={Image} sx={{ position: "relative" }} />
      </MatrixImage>

      <Grid
        item
        container
        justifyContent="center"
        alignContent={"center"}
        alignItems={"center"}
        bgcolor={bgcolor}
        height={height}
      >
        <StrapiGridItemDanger
          sx={{ maxWidth: 257 }}
          textBlockStyle={{ innerStyles: { p: { color: "white", fontSize: "18px" } } }}
          Copy={Copy}
        />
      </Grid>
    </Grid>
  );
}
const MatrixImage = styled(Grid)``;
