import React from "react";
import styled from "@emotion/styled";
import { Box, Grid, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import NewsPlaceholderImage from "./NewsPlaceholderImage";

export default function NewsCard({ handleNewsClick, theme, ...item }) {
  const { Url, PublishDate, Summary, Title } = item;

  // Create a Date obj  ect
  const displayDate = new Date(`${PublishDate} MST`);

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the month, day, and year
  const month = monthNames[displayDate.getMonth()];
  const day = displayDate.getDate();
  const year = displayDate.getFullYear();

  // Create the formatted string
  const formattedDateString = `${month} ${day}, ${year}`;

  return (
    <Grid
      item
      xs={12}
      md={5.75}
      sx={{
        borderBottom: "2px solid rgba(16,72,100,.25)",
      }}
    >
      <MuiLink
        component={Link}
        to={`/news/${item.id}`}
        underline="hover"
        data-id={item.id}
        onClick={handleNewsClick}
        // onClick={localHandleClick}
      >
        <Typography variant="body1" align="left" sx={{ m: "2rem 0 1rem" }}>
          {PublishDate ? formattedDateString : "No Publish Date"}
        </Typography>
        <TitleWrapper variant="body2" align="left" color="primary.main">
          {Title}
        </TitleWrapper>
      </MuiLink>
    </Grid>
  );
}

const TitleWrapper = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  overflow: hidden;
  text-align: left;
  min-height: 56px;
  font-size: 1rem;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const SummaryWrapper = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
