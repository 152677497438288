import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { parseWithLinks } from "../Functions";
import { Container, Box, Typography, IconButton, Grid } from "@mui/material";

import SimpleResponsivePicture from "./SimpleResponsivePicture";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

export default function Properties(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [PropertyImage, setPropertyImage] = useState({ image: null, options: {} });

  const [HideImage, setHideImage] = useState(false);

  const handleNext = () => {
    // The image can have css properties that affect it's position.
    // When the property changes the css is updated and can cause janky repositioning
    // HideImage is used to show a blank div while the image data is updated.

    setHideImage(prev => true);
    setCurrentIndex(prevIndex => {
      return (prevIndex + 1) % props?.properties?.data?.length;
    });
  };

  const handlePrevious = () => {
    setHideImage(prev => true);
    setCurrentIndex(prevIndex => {
      return (prevIndex - 1 + props?.properties?.data?.length) % props?.properties?.data?.length;
    });
  };
  useEffect(() => {
    // Initial image is first property
    setPropertyImage(prev => {
      return {
        image: props?.properties?.data[currentIndex]?.attributes?.Images?.data[0]?.attributes,
        options: { ...props?.properties?.data[currentIndex]?.attributes?.options },
      };
    });
    setHideImage(prev => {
      return false;
    });
  }, [currentIndex]);

  useEffect(() => {
    setPropertyImage(prev => {
      return {
        image: props?.properties?.data[0]?.attributes?.Images?.data[0]?.attributes,
        options: { ...props?.properties?.data[0]?.attributes?.options },
      };
    });
  }, []);

  return (
    <Container sx={{ padding: { xs: "1rem", md: "5rem 0" } }}>
      {parseWithLinks(props?.Copy, { alignText: "center" })}
      <Box component={Grid} container sx={{ marginTop: "4rem" }} justifyContent={"center"}>
        <Grid
          container
          item
          xs={12}
          sm={3}
          md={3}
          direction={"column"}
          justifyContent={"space-between"}
        >
          <Box sx={{ padding: ".75rem" }}>
            <Typography
              variant="h3"
              sx={{ maxWidth: 250, fontWeight: 600, lineHeight: 1.25, marginBottom: 0 }}
            >
              {props?.properties?.data[currentIndex]?.attributes?.Name}
            </Typography>
            <Box sx={{ padding: ".75rem" }}>
              <Typography paragraph sx={{ lineHeight: "1.4875rem" }}>
                <strong>City:</strong>
                <br />
                {props?.properties?.data[currentIndex]?.attributes?.City}
              </Typography>
              <Typography paragraph sx={{ lineHeight: "1.4875rem" }}>
                <strong>Rentable Area:</strong>
                <br />
                {props?.properties?.data[currentIndex]?.attributes?.Area.toLocaleString()} Sq. Ft.
              </Typography>
              <Typography paragraph sx={{ lineHeight: "1.4875rem" }}>
                <strong>Type:</strong>
                <br />
                {props?.properties?.data[currentIndex]?.attributes?.Type}
              </Typography>
            </Box>
          </Box>

          <Box
            component={Grid}
            container
            justifyContent={currentIndex === 0 ? "flex-end" : "space-between"}
            sx={{ width: { xs: "100%", md: "80%" } }}
          >
            {currentIndex !== 0 && (
              <IconButton
                aria-label="previous"
                onClick={handlePrevious}
                disabled={currentIndex === 0}
              >
                <ArrowCircleLeftIcon fontSize="large" color={"secondary"} />
              </IconButton>
            )}
            {currentIndex !== props?.properties?.data.length - 1 && (
              <IconButton
                aria-label="next"
                onClick={handleNext}
                disabled={currentIndex === props?.properties?.data.length - 1}
              >
                <ArrowCircleRightIcon fontSize="large" color={"secondary"} />
              </IconButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} justifyContent={"flex-end"}>
          {!HideImage && (
            <SimpleResponsivePicture
              // useZoom={true}
              // zoomScale={1}
              hideImage={HideImage}
              sx={{
                width: { xs: "100%", md: 698 },
                height: 460,
                ...PropertyImage.options,
              }}
              {...PropertyImage.options}
              imageObj={PropertyImage.image}
            />
          )}

          {/* The following is a stub to limit CLS when switching */}
          {HideImage && (
            <Box
              sx={{
                width: { xs: "100%", md: 698 },
                height: 460,
              }}
            />
          )}
        </Grid>
      </Box>
    </Container>
  );
}
const Wrapper = styled(Container)``;
