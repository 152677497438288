export default function addClassToLinksInParagraphs(htmlString, className) {
  // Create a temporary HTML element to parse the input string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Select all <p> elements containing <a> tags within the parsed HTML
  const paragraphs = tempElement.querySelectorAll("p");

  // Loop through the paragraphs and add the class to their child <a> tags
  paragraphs.forEach(paragraph => {
    const links = paragraph.querySelectorAll("a");
    links.forEach(link => {
      link.classList.add(className);
    });
  });

  // Return the modified HTML as a string
  return tempElement.innerHTML;
}
