import React from "react";
import styled from "@emotion/styled";
import { Container, Box, Typography } from "@mui/material";
import Gallery from "./Gallery";
// import Masonry from "./Masonry";

export default function MultipleImages(props) {
  const disableGutters = props?.options?.wrapperStyle?.disableGutters || true;
  return (
    <Box
      component={Container}
      maxWidth={"lg"}
      align="center"
      sx={props?.options?.wrapperStyle?.sx}
      disableGutters={disableGutters}
    >
      <Typography variant="h2">{props.Title}</Typography>
      <Gallery images={props?.Images?.data} options={props?.options} />
      {/* <Masonry {...props} options={props?.options} /> */}
    </Box>
  );
}
