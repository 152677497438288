import React from "react";
import { Grid, Typography } from "@mui/material";
import Description from "./Description";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import ClickableImage from "./ClickableImage";
export default function LocationMatters({ isSmall, isXsmall, isTouch, ...props }) {
  const useZoom = (isSmall || isXsmall) && isTouch;
  return (
    <Description Copy={props?.Description?.Copy} options={props?.Description?.options}>
      <Grid container justifyContent={"space-evenly"} rowSpacing={5} pt={5}>
        <Grid item xs={12} sm={5.5}>
          {!useZoom && (
            <ClickableImage isTouch={isTouch} data={props?.Area?.data?.attributes}>
              Click to enlarge
            </ClickableImage>
          )}
          {useZoom && (
            <SimpleResponsivePicture
              imageObj={props?.Area?.data?.attributes}
              useZoom={useZoom}
              zoomScale=".35"
              fullscreenOnMobile={true}
              mobileBreakpoint={2500}
              width={"100%"}
              height={"auto"}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={5.5}>
          {!useZoom && (
            <ClickableImage isTouch={isTouch} data={props?.Phases?.data?.attributes}>
              Click to enlarge
            </ClickableImage>
          )}
          {useZoom && (
            <SimpleResponsivePicture
              imageObj={props?.Phases?.data?.attributes}
              useZoom={useZoom}
              zoomScale=".35"
              fullscreenOnMobile={true}
              mobileBreakpoint={2500}
              width={"100%"}
              height={"auto"}
            />
          )}
        </Grid>
      </Grid>
    </Description>
  );
}
