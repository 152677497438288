import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, NavLink } from "react-router-dom";
import { useTheme, Fab, useMediaQuery, Container, Hidden, IconButton, Alert } from "@mui/material";
import { Block } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { motion, AnimatePresence } from "framer-motion";

import ScrollTop from "./components/ScrollTop";
import { Event, initGA } from "./components/Tracking";
import styled from "@emotion/styled";

import ArrowUpward from "@mui/icons-material/ArrowUpward";

import "./App.css";

import {
  // initGA,
  // PageView,
  // Event,
  MainNavigation,
  MobileMenu,
  Palette,
  // PropertyListing,
  Home,
  Default,
  NoMatch,
  Footer,
} from "./components/index.js";

import { scrollToTop, getStrapiContent, convertColor } from "./Functions";

const pageAnimations = {
  hidden: {
    opacity: 0.05,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.125,
    },
  },

  exit: { opacity: 0.0, transition: { duration: 0.125 } },
};

function App(props) {
  // useEffect(() => initGA("G-6SGLHZRBJC"), []);
  const locationObject = useLocation();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isXsmall = useMediaQuery(theme.breakpoints.only("xs"));
  const showTextNav = useMediaQuery(theme.breakpoints.up("lg"));

  //Utility state
  const [message, setMessage] = useState("");
  const [IsLoadingData, setIsLoadingData] = useState(true);
  const [Error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  //CMS Content
  const [HomeData, setHomeData] = useState([{}]);
  const [CareersData, setCareersData] = useState([{}]);
  const [ServicesData, setServicesData] = useState([{}]);
  const [StoryData, setStoryData] = useState([{}]);
  const [TeamData, setTeamData] = useState([{}]);
  const [ContactData, setContactData] = useState();

  //??
  const [propertyId, setPropertyId] = useState();
  const [propertyDetail, setPropertyDetail] = useState();

  // const handleOnTouchStart = event => {
  //   setIsTouch(true);
  // };

  useEffect(() => {
    const isTouchDevice = "ontouchstart" in document.documentElement;
    setIsTouch(isTouchDevice);
  }, []);
  const contentParameters = [
    {
      apiPath: "/api/home/",
      setter: setHomeData,
      data: HomeData,
      localStorageVariableName: "home",
      path: "/",
      pageTitle: "Welcome",
      pageId: "home",
      name: "Home",
      altText: "Riverpark Home",
      // component: Layout,
    },

    {
      apiPath: "/api/story/",
      setter: setStoryData,
      data: StoryData,
      localStorageVariableName: "story",
      path: "/our-story",
      link: "/our-story",
      pageTitle: "Our Story",
      pageId: "story",
      name: "Our Story",
      altText: "Riverpark Properties – Our Story",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/service/",
      setter: setServicesData,
      data: ServicesData,
      localStorageVariableName: "service",
      path: "/our-services",
      link: "/our-services",
      pageTitle: "Our Services",
      pageId: "Services",
      name: "Our Services",
      altText: "Riverpark Properties – Our Services",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/team",
      setter: setTeamData,
      data: TeamData,
      localStorageVariableName: "team",
      path: "/our-team",
      link: "/our-team",
      pageTitle: "Our Team",
      pageId: "team",
      name: "Our Team",
      altText: "Riverpark Properties – Our Team",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/career/",
      setter: setCareersData,
      data: CareersData,
      localStorageVariableName: "careers",
      path: "/careers",
      link: "/careers",
      pageTitle: "Careers",
      pageId: "careers",
      name: "Careers",
      altText: "Riverpark Properties Careers",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      apiPath: "/api/contact",
      setter: setContactData,
      data: ContactData,
      localStorageVariableName: "contact",
      path: "/contact",
      link: "/contact",
      pageTitle: "Contact",
      pageId: "contact",
      name: "Contact",
      altText: "Riverpark Properties – Contact",
      navComponent: NavLink,
      // component: Layout,
    },
    {
      link: "https://connect.buildingengines.com/login",

      name: "Tenant Services Portal",
      altText: "Riverpark Properties – Tenant Services Portal",
      navComponent: NavLink,
      // component: Layout,
    },
  ];

  useEffect(() => {
    contentParameters.forEach(
      async ({ apiPath, setter, localStorageVariableName }, index, array) => {
        if (apiPath) {
          try {
            await getStrapiContent(apiPath, setter, localStorageVariableName).then(res => {
              if (apiPath === "/api/home/" && res?.createdAt) {
                setIsLoadingData(false);
                return true;
              }
            });
          } catch (error) {
            setError(error);
            return false;
          }
        }
      }
    );
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <>
      <Container
        className="App"
        maxWidth="xl"
        disableGutters
        // onTouchStart={handleOnTouchStart}
      >
        {showTextNav && (
          <MainNavigation
            scrollToTop={scrollToTop}
            setIsModalOpen={setIsModalOpen}
            theme={theme}
            contentParameters={contentParameters}
          />
        )}
        {!showTextNav && (
          <MobileMenu
            scrollToTop={scrollToTop}
            contentParameters={contentParameters}
            theme={theme}
          />
        )}
        <ContentContainer disableGutters maxWidth="xl">
          <Routes>
            <Route path="/palette" element={<Palette />} />
            {/* <Route path="/" index exact element={<Navigate replace to="home" />} /> */}

            <Route
              index
              exact
              path="/"
              element={
                <React.Fragment>
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={locationObject.pathname}
                      exit="exit"
                      initial="hidden"
                      animate="visible"
                      variants={pageAnimations}
                    >
                      {showAlert && (
                        <Alert
                          severity="error"
                          style={{
                            display: Block,
                            maxWidth: "fit-content",
                            margin: "0 auto",
                          }}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setShowAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {message}
                        </Alert>
                      )}

                      <Home
                        convertColor={convertColor}
                        theme={theme}
                        data={HomeData}
                        isLoading={!HomeData?.createdAt}
                        isSmall={isSmall}
                        isTouch={isTouch}
                        isXsmall={isXsmall}
                        scrollToTop={scrollToTop}
                      />
                    </motion.div>
                  </AnimatePresence>
                </React.Fragment>
              }
            />

            <Route
              path="/our-services"
              element={
                <Default
                  theme={theme}
                  data={ServicesData}
                  pageTitle="Our Services"
                  pageId="our-services"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!ServicesData?.createdAt}
                />
              }
            />
            <Route
              path="/our-story"
              element={
                <Default
                  theme={theme}
                  data={StoryData}
                  pageTitle="Our Story"
                  pageId="our-story"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!StoryData?.createdAt}
                />
              }
            />
            <Route
              path="/our-team"
              element={
                <Default
                  theme={theme}
                  data={TeamData}
                  pageTitle="Our Team"
                  pageId="our-team"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!TeamData?.createdAt}
                />
              }
            />

            <Route
              path="/careers"
              element={
                <Default
                  theme={theme}
                  data={CareersData}
                  pageTitle="Careers"
                  pageId="careers"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!CareersData?.createdAt}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <Default
                  theme={theme}
                  data={ContactData}
                  pageTitle="Contact"
                  pageId="contact"
                  isSmall={isSmall}
                  isXsmall={isXsmall}
                  isTouch={isTouch}
                  isLoading={!ContactData?.createdAt}
                />
              }
            />

            <Route path="*" element={<NoMatch />} />
          </Routes>
        </ContentContainer>
        {!isXsmall && <Footer isSmall={isSmall} isXsmall={isXsmall} />}
        {isXsmall && <Footer isSmall={isSmall} isXsmall={isXsmall} />}
        {/* {isXsmall && <FooterXS />} */}
      </Container>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <ArrowUpward />
        </Fab>
      </ScrollTop>
    </>
  );
}

const ContentContainer = styled(Container)`
  margin-top: 55px;
  ${props => props.theme.breakpoints.up("sm")} {
    margin-top: 66px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    margin-top: 66px;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    margin-top: 90px;
  }
`;

export default App;
