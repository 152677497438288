import axios from "axios";
import { getLocalStorage, removeCommentAttributes } from "./index.js";

const qs = require("qs");

export default async function getStrapiContent(path, setter, key) {
  //"http://[API_ENDPOINT]:1337/api/home/?populate[Hero][populate]=%2A&populate[Intro][populate]=%2A"

  let RETURN_VALUE = null;
  const query = qs.stringify(
    {
      populate: "deep",
    },
    {
      encodeValuesOnly: true,
    }
  );

  const config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
    },
  };

  localStorage.removeItem(key);
  return await axios
    .get(`${process.env.REACT_APP_CMS_URL}${path}?${query}`, config)
    .then(res => {
      const dataTest = removeCommentAttributes(
        JSON.stringify(res.data.data.attributes || res.data.data || res)
      );

      let modifiedData = JSON.parse(dataTest);
      // if (path === "/api/properties") {
      //   debugger;
      // }

      modifiedData = JSON.parse(
        dataTest.replace(/"\/uploads\/+/g, `"${process.env.REACT_APP_CMS_URL}/uploads/`)
      );
      // if (path !== "/api/home/") {
      //   modifiedData = JSON.parse(
      //     dataTest.replace(/\/uploads\/+/g, `${process.env.REACT_APP_CMS_URL}/uploads/`)
      //   );
      // }

      setter(() => modifiedData);
      // localStorage.setItem(key, JSON.stringify(modifiedData));
      RETURN_VALUE = modifiedData;
      return modifiedData;
    })
    .catch(function (error) {
      if (key === "cw-builder") {
        localStorage.removeItem(key);
        return;
      }
      // getLocalStorage(setter, key);
      console.log(`Error fetching page data from server :: ${key}`, error.message);
      return error;
    });
}
