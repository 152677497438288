import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
function NoMatch(props) {
  let location = useLocation();

  return (
    <Wrapper
      alignItems="center"
      alignContent="center"
      container
      justifyContent="center"
      pb={"5rem"}
    >
      <Typography variant="h1" pt={2} paragraph>
        404
      </Typography>
      <Typography variant="h1" paragraph ml={"2rem"}>
        |
      </Typography>
      <Typography ml={"2rem"} variant="h6">
        Page not found.
      </Typography>
      <Grid item xs={12} align="center">
        <Typography>
          {window.location.hostname}
          {location.pathname}{" "}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography>Use the site navigation for correct links.</Typography>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  height: 100vh;
`;
NoMatch.propTypes = {};

export default NoMatch;
