import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Link, Box, Container, Hidden } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Obfuscate from "react-obfuscate";
import { ReactComponent as FooterLogo } from "../assets/navLogo.svg";

export default function FooterXS({ isSmall, ...props }) {
  const today = new Date();

  // TODO Fix the layout on phone/touch

  return (
    <Box component={Container} maxWidth={"lg"} disableGutters mt="4rem">
      <Box
        component={"footer"}
        sx={{
          display: "grid",
          // gridTemplateAreas: { xs: '"logo  nav1"', md: '"logo  nav1  nav2"' },
          // gridTemplateColumns: { xs: "25% auto", md: "1fr 2fr 2.25fr" },
          // gridGap: { xs: "2rem", md: "5rem" },
          padding: { xs: "2rem 2rem", md: "2rem 4rem" },
          alignItems: "center",
          alignContent: "space-evenly",
          justifyItems: "flex-start",
        }}
        bgcolor="primary.dark"
        p="4rem 2rem"
      >
        <Box>
          <RouterLink to="/" aria-label="Centron Home">
            <FooterLogo style={{ width: 200, height: "auto" }} />
          </RouterLink>
        </Box>
        <Box mt="1rem">
          <Typography variant="body1" color="common.white">
            #104, 8826 Blackfoot Trail SE
          </Typography>
          <Typography variant="body1" color="common.white">
            Calgary AB | T2J 3J1
          </Typography>
        </Box>
        <Box mt="1rem">
          <FooterLink
            variant="body1"
            href="tel:(403) 252-1120"
            color="common.white"
            underline="none"
          >
            (403) 252-1120
          </FooterLink>
        </Box>
        <Box>
          <FooterLink
            variant="body1"
            color="common.white"
            underline="none"
            to="mailto:info@centrongroup.com"
          >
            info@centrongroup.com
          </FooterLink>
        </Box>
        <Box mt="1rem">
          <Typography variant="body2" align="center" color="common.white">
            ©Centron {today.getFullYear()}{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const FooterLink = styled(Link)`
  text-align: left;
  display: inline-block;
  width: max-content;
  color: ${props => props.theme.palette.common.white}!important;
  &:visited {
    color: ${props => props.theme.palette.common.white}!important;
  }
`;

const Logo = styled("img")`
  padding-bottom: 2rem;
  ${props => props.theme.breakpoints.up("sm")} {
    padding-bottom: unset;
  }
`;
