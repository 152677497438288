import React from "react";
import ImageGallery from "react-image-gallery";
import { cleanCaption } from "../../Functions";
import "react-image-gallery/styles/css/image-gallery.css";

//TODO use the strapiImageObject to build images array with srcset and sizes prop.
export default function Gallery({ options, ...props }) {
  // TODO Remove the prefix url or just build the development server already

  const images = props?.images?.map((image, index) => {
    //TODO consider removing this once the captions have been edited
    // xlarge: 1920,
    // large: 1000,`
    // medium: 750,
    // small: 500,
    // xsmall: 64,

    const returnObject = {
      // TODO use srcset
      // TODO use sizes
      srcSet: `${image?.attributes?.formats?.small?.url} 320w,${
        image?.attributes?.formats?.medium?.url
      } 640w,${image?.attributes?.formats?.large?.url || image?.attributes?.url} 1280w,${
        image?.attributes?.formats?.xlarge?.url || image?.attributes?.url
      } 1920w`,
      sizes: "(min-width: 768px) 50vw, 100vw",
      original: `${
        image?.attributes?.formats?.xlarge?.url ||
        image?.attributes?.formats?.large?.url ||
        image?.attributes?.formats?.medium?.url ||
        image?.attributes?.formats?.small?.url ||
        image?.attributes?.url
      }`,
      thumbnail: `${image?.attributes?.formats?.thumbnail?.url}`,
      fullscreen: `${image?.attributes?.url}`,
      description: cleanCaption(image?.attributes?.caption),
    };

    return returnObject;
  });

  return <ImageGallery items={images} {...options.settings} />;
}
