import React from "react";

// Import the components you want to dynamically load
import ImageLeft from "./ImageLeft";
import ImageRight from "./ImageRight";
import PlainText from "./PlainText";
import RichTextLight from "./RichTextLight";
import RichTextRich from "./RichTextRich";
import RichTextStandard from "./RichTextStandard";
import VideoLeft from "./VideoLeft";
import VideoRight from "./VideoRight";
import Description from "./Description";
import FullWidthImage from "./FullWidthImage";
import Hero2 from "./Hero2";
import Hero from "./Hero";
import Embed from "./Embed";
import Headshots from "./Headshots";
import ImageGroup from "./ImageGroup";
import AddressGroup from "./AddressGroup";
import Testimonials from "./Testimonials";
import ThreeByTwoMatrix from "./ThreeByTwoMatrix";

import NewsListingComponent from "./NewsListingComponent";
import Slider from "./Slider";
import RenderJsonBlocs from "./RenderJsonBlocks";
import WhatWeDo from "./WhatWeDo";

import ServicesAndSolutions from "./ServicesAndSolutions";
import GraphicAndText from "./GraphicAndText";

import TwoColumn from "./TwoColumn";
import Careers from "./Careers";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

import TwoImages from "./TwoImages";
import Registration from "./Registration";
import Accordian from "./Accordian";

import StyleIcons from "./StyleIcons";

import ClubhouseIcons from "./ClubhouseIcons";
import Video from "./Video";
import Services from "./Services";
import Gallery from "./Gallery";
import ShowHomes from "./ShowHomes";

import MultipleImages from "./MultipleImages";

import LocationMatters from "./LocationMatters";
import TabSection from "./TabSection";
import Properties from "./Properties";
import TeamMembers from "./TeamMembers";

export default function DynamicComponentLoader(props) {
  let ComponentToLoad = null;
  let options = {};

  // Determine which component to load based on the prop value
  switch (props.__component) {
    case "static.team-members":
      ComponentToLoad = TeamMembers;
      break;
    case "content.properties":
      ComponentToLoad = Properties;
      break;
    case "content.tab-section":
      ComponentToLoad = TabSection;
      break;
    case "content.location-matters":
      ComponentToLoad = LocationMatters;
      break;

    case "content.services":
      ComponentToLoad = Services;
      break;
    case "content.video":
      ComponentToLoad = Video;
      break;
    case "static.clubhouse-icons":
      ComponentToLoad = ClubhouseIcons;
      break;
    case "content.home-hero":
      ComponentToLoad = Hero;
      break;
    // case "static.leasing-form":
    //   ComponentToLoad = LeasingForm;
    //   break;
    case "content.showhomes":
      ComponentToLoad = ShowHomes;
      break;
    case "content.style-icons":
      ComponentToLoad = StyleIcons;
      break;
    case "content.three-by-two-matrix":
      ComponentToLoad = ThreeByTwoMatrix;
      break;
    case "content.image-right":
      ComponentToLoad = ImageRight;
      break;
    case "content.image-left":
      ComponentToLoad = ImageLeft;
      break;
    case "content.plain-text":
      ComponentToLoad = PlainText;
      break;
    case "content.rich-text-light":
      ComponentToLoad = RichTextLight;
      break;
    case "content.rich-text-rich":
      ComponentToLoad = RichTextRich;
      break;
    case "content.rich-text-standard":
      ComponentToLoad = RichTextStandard;
      break;
    case "content.video-left":
      ComponentToLoad = VideoLeft;
      break;
    case "content.video-right":
      ComponentToLoad = VideoRight;
      break;
    case "content.description":
      ComponentToLoad = Description;
      break;
    case "content.hero":
      const HydratedHero = () => {
        return (
          <Hero2
            theme={props.theme}
            imageObj={props?.Image?.data[0]?.attributes}
            Copy={props?.Title}
          />
        );
      };
      ComponentToLoad = HydratedHero;
      break;
    case "content.embed":
      ComponentToLoad = Embed;
      break;
    case "content.full-width-image":
      ComponentToLoad = FullWidthImage;
      break;
    case "content.headshots":
      ComponentToLoad = Headshots;
      break;
    case "content.image-group":
      ComponentToLoad = ImageGroup;

      break;
    case "content.address-group":
      ComponentToLoad = AddressGroup;
      break;
    case "content.testimonials":
      ComponentToLoad = Testimonials;
      break;
    case "content.multiple-images":
      ComponentToLoad = MultipleImages;
      break;
    case "content.json-text":
      ComponentToLoad = RenderJsonBlocs;
      break;
    case "content.what-we-do":
      ComponentToLoad = WhatWeDo;
      break;
    case "content.news-section":
      ComponentToLoad = NewsListingComponent;
      break;
    case "content.services-and-solutions":
      ComponentToLoad = ServicesAndSolutions;
      break;
    case "content.graphic-and-text":
      ComponentToLoad = GraphicAndText;
      break;

    case "content.two-column":
      ComponentToLoad = TwoColumn;
      break;
    case "content.careers":
      ComponentToLoad = Careers;
      break;
    case "static.contact-form":
      ComponentToLoad = ContactForm;
      break;
    case "content.contact-info":
      ComponentToLoad = ContactInfo;
      break;

    case "content.two-images":
      ComponentToLoad = TwoImages;
      break;
    case "static.registration":
      ComponentToLoad = Registration;
      break;
    case "content.accordian":
      ComponentToLoad = Accordian;
      break;

    // case "static.contact-form":
    //   const HydratedContactForm = () => {};
    //   ComponentToLoad = ContactForm;
    //   break;
    default:
      // Default component if prop doesn't match any case
      ComponentToLoad = () => <div>Invalid Component Name "{props.__component}"</div>;
  }

  return <ComponentToLoad options={options} {...props} />;
}
