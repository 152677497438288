import React from "react";
import { Container, Box, Typography, Grid, Link } from "@mui/material";

import { parseWithLinks } from "../Functions";
export default function ShowHomes({ showHomeData, ...props }) {
  //   const Builders = [
  //     ...new Set(
  //       showHomeData?.map((showHome, index) => {
  //         return showHome.attributes?.home_builder?.data?.attributes.Name;
  //       })
  //     ),
  //   ];

  // TODO abstract this array from data in showHomeData
  //The order is set here.
  const Types = ["Front Drive", "Paired", "Laned", "Row", "Quarter", "Condo"];

  return (
    <></>
    // <Container maxWidth="lg" disableGutters>
    //   {Types?.map((type, index) => {
    //     return (
    //       <Box key={type} sx={{ border: "1px solid rgba(16,72,100,.25)", p: "2rem" }}>
    //         <Typography
    //           variant="body1"
    //           fontWeight={800}
    //           textTransform={"uppercase"}
    //           color={"primary"}
    //           paragraph
    //         >
    //           {type} Showhomes
    //         </Typography>
    //         <Grid container justifyContent={"flex-start"}>
    //           {showHomeData?.map((showHome, index) => {
    //             if (showHome.attributes.type === type) {
    //               return (
    //                 <Grid
    //                   key={showHome.attributes?.home_builder?.data?.attributes.Name + index}
    //                   item
    //                   xs={12}
    //                   sm={4}
    //                   md={4}
    //                 >
    //                   <Typography variant="body1" fontWeight={800}>
    //                     <Link
    //                       href={showHome.attributes?.home_builder?.data?.attributes.url}
    //                       target="Clearwater"
    //                       underline={"none"}
    //                     >
    //                       {showHome.attributes?.home_builder?.data?.attributes.Name}
    //                     </Link>
    //                   </Typography>
    //                   <Typography variant="body1" fontWeight={800}>
    //                     {parseWithLinks(showHome.attributes?.Address, { alignText: "left" })}
    //                   </Typography>
    //                 </Grid>
    //               );
    //             }
    //           })}
    //         </Grid>
    //       </Box>
    //     );
    //   })}
    // </Container>
  );
}
