import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import Placeholder from "../assets/placeholder2.webp";
import StrapiGrid from "./StrapiGrid";

import BioModal from "./BioModal";
import { AspectRatio } from "@mui/icons-material";
export default function Headshots({ isSmall, ...props }) {
  return (
    <StrapiGrid options={props?.options.layout}>
      <Grid item xs={12}>
        <Typography variant="h2" color="common.white" sx={{ marginBottom: "2rem" }}>
          {props.Title}
        </Typography>
      </Grid>
      {props?.TeamMember &&
        props?.TeamMember?.map((member, index) => {
          return (
            <Grid item key={member.id} xs={12} sm={4} md={2}>
              <PersonWrapper
                container
                direction={"column"}
                alignContent={"center"}
                className="person"
              >
                <SimpleResponsivePicture
                  imageObj={member?.graphic?.data?.attributes || { url: Placeholder }}
                  sx={
                    member?.graphic?.data?.attributes
                      ? {
                          width: { xs: "calc(100svw - 4rem)", sm: 170, md: 170 },
                          aspectRatio: 170 / 162,
                        }
                      : {
                          width: { xs: "calc(100svw - 4rem)", sm: 170, md: 170 },
                          aspectRatio: 170 / 162,
                          boxShadow: "0 0 5px black",
                        }
                  }
                />
                <Grid item align="left" dangerouslySetInnerHTML={{ __html: member?.copy }} />
                <BioModal
                  {...member}
                  trigger={props.trigger}
                  theme={props.theme}
                  isSmall={isSmall}
                  buttonStyle={{ maxWidth: { xs: "calc(100vw - 4rem)", md: 170 } }}
                />
              </PersonWrapper>
            </Grid>
          );
        })}
    </StrapiGrid>
  );
}

const PersonWrapper = styled(Grid)`
  & > div > h4 {
    margin: 1rem 0 0.25rem;
  }
  & > div > p {
    margin: 0 0 1rem;
  }
`;
