import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Dialog, Typography } from "@mui/material";
function ZoomedImage({
  showLabel = false,
  open,
  setOpen,
  useBackground,
  image,
  width,
  aspect,
  imageAlt,
  ...props
}) {
  function handleBackdropClick(event) {
    setOpen(false);
  }
  return (
    <>
      <Wrapper
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClick={handleBackdropClick}
        onClose={handleBackdropClick}
        useBackground={useBackground}
      >
        <BigImage
          src={image}
          width={width}
          aspect={aspect}
          useBackground={useBackground}
          alt={imageAlt}
          onClick={handleBackdropClick}
          loading="lazy"
        >
          {showLabel && (
            <Typography align="center" variant="h1">
              {imageAlt}
            </Typography>
          )}
        </BigImage>
      </Wrapper>
    </>
  );
}
const Wrapper = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    background: unset;
    background-color: ${props =>
      props.useBackground ? props.theme.palette.common.backgroundGrey : null};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-shadow: none;
    margin: 0;
    max-height: calc(100vh - 12px);
  }
`;
const BigImage = styled("div")`
  background: url(${props => props.src}) center/auto 90% no-repeat;
  background-color: ${props =>
    props.useBackground ? props.theme.palette.common.backgroundGrey : null};
  width: 100%;
  height: 100vh;

  @media (orientation: portrait) {
    background: url(${props => props.src}) center/90% auto no-repeat;
  }
  @media (orientation: landscape) {
    background: url(${props => props.src}) center/ auto 90% no-repeat;
  }

  ${props => props.theme.breakpoints.up("md")} {
    background: url(${props => props.src}) center/contain no-repeat;
  }

  &:hover {
    cursor: zoom-out;
  }
  & > h1 {
    margin-top: 2rem;
  }
`;
ZoomedImage.propTypes = {};

export default ZoomedImage;
