import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import MatrixGroup from "./MatrixGroup";
import MatrixPlaceholder from "./MatrixPlaceholder.jpg";

const data = {
  content: [
    {
      attributes: {
        name: "HeroCommunity.jpg",
        alternativeText: null,
        caption: null,
        width: 1500,
        height: 1001,
        formats: {
          large: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          small: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          medium: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          xlarge: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          xsmall: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone1x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone2x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone3x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          third2x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          thumbnail: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          imageText1X: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          imageText2X: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
        },
        hash: "Hero_Community_346dd66865",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 167.63,
        url: MatrixPlaceholder,
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        createdAt: "2023-09-06T21:57:27.477Z",
        updatedAt: "2023-09-06T21:57:27.477Z",
      },
      copy: `We make a living by what 
      we get, but we make a life 
      by what we give.`,
    },
    {
      attributes: {
        name: "HeroCommunity.jpg",
        alternativeText: null,
        caption: null,
        width: 1500,
        height: 1001,
        formats: {
          large: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          small: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          medium: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          xlarge: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          xsmall: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone1x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone2x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone3x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          third2x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          thumbnail: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          imageText1X: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          imageText2X: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
        },
        hash: "Hero_Community_346dd66865",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 167.63,
        url: MatrixPlaceholder,
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        createdAt: "2023-09-06T21:57:27.477Z",
        updatedAt: "2023-09-06T21:57:27.477Z",
      },
      copy: `“Service to others 
      is the rent you pay for your 
      room here on earth.”`,
    },
    {
      attributes: {
        name: "HeroCommunity.jpg",
        alternativeText: null,
        caption: null,
        width: 1500,
        height: 1001,
        formats: {
          large: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          small: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          medium: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          xlarge: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          xsmall: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone1x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone2x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          phone3x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          third2x: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          thumbnail: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          imageText1X: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
          imageText2X: {
            ext: ".jpg",
            url: MatrixPlaceholder,
            hash: "large_Hero_Community_346dd66865",
            mime: "image/jpeg",
            name: "large_HeroCommunity.jpg",
            path: null,
            size: 62.56,
            width: 1500,
            height: 1001,
          },
        },
        hash: "Hero_Community_346dd66865",
        ext: ".jpg",
        mime: "image/jpeg",
        size: 167.63,
        url: MatrixPlaceholder,
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        createdAt: "2023-09-06T21:57:27.477Z",
        updatedAt: "2023-09-06T21:57:27.477Z",
      },
      copy: `“The most precious
      thing I have to give,
      is my time.”`,
    },
  ],
};

export default function ThreeByTwoMatrix({ MatrixImageText, ...props }) {
  const imageBlockStyle = props?.options?.imageBlockStyle || {};
  const textBlockStyle = props?.options?.textBlockStyle || {};
  const { justifyContent, alignContent, columnGap, wrap, ...layoutOptions } =
    props?.options?.layout || false;
  const layout = props?.options?.layout || {};
  return (
    <Grid container sx={layout?.sx} direction={{ xs: "column", sm: "row", md: "row" }}>
      {MatrixImageText.map((MatrixItem, index) => {
        const isEven = index % 2 === 0;
        const bgcolor = isEven
          ? props?.theme?.palette?.primary?.main
          : props?.theme?.palette?.primary?.dark;
        const direction = isEven ? "" : "-reverse";
        return (
          <Grid item xs={4}>
            <MatrixGroup
              Image={MatrixItem.Image.data.attributes}
              Copy={MatrixItem.Copy}
              theme={props?.theme}
              bgcolor={bgcolor}
              isEven={isEven}
              direction={direction}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
