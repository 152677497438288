import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Link, Box, Container, Hidden } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as FooterLogo } from "../assets/FooterLogo.svg";

import LinkedInBug from "../assets/LI-In-Bug.png";

export default function Footer({ isSmall, isXsmall, ...props }) {
  const today = new Date();

  // TODO Fix the layout on phone/touch

  return (
    <Container maxWidth={"xl"} disableGutters sx={{}}>
      <Box
        component={"footer"}
        bgcolor="primary.dark"
        sx={{
          display: "grid",
          gridTemplateAreas: {
            xs: `"location"`,
            sm: `"location"`,
            md: `"logo location links"`,
            lg: `"logo location links"`,
          },
          gridTemplateColumns: { xs: "1fr", sm: "1fr", md: ".5fr 1.25fr .5fr" },
          gridColumnGap: { xs: "unset", sm: "3rem", md: "1rem" },
          padding: { md: "3rem 6rem", xs: "1rem", sm: "3rem" },
        }}
      >
        {!isXsmall && !isSmall && (
          <Box sx={{ gridArea: "logo", alignSelf: { lg: "center" } }}>
            <a href="/" aria-label="Riverpark Home">
              <FooterLogo style={{ width: 188, height: "auto", marginBottom: "2rem" }} />
            </a>
            <Typography sx={{}} variant="body1" color="common.white" paragraph>
              <Link
                href="https://ca.linkedin.com/company/riverpark-properties-ltd"
                alt="Riverpark LinkedIn"
                sx={{ color: "white" }}
                target="riverpark"
                rel="noopener"
              >
                Follow Us On LinkedIn
                <img
                  src={LinkedInBug}
                  style={{ width: 24, height: "auto", verticalAlign: "middle", marginLeft: "1rem" }}
                  alt="LinkedIn"
                />
              </Link>
            </Typography>
          </Box>
        )}

        <Box sx={{ gridArea: "location" }}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            color="common.white"
          >
            Office and Mailing Address:
          </Typography>
          <Typography
            sx={{
              // whiteSpaceCollapse: "preserve-breaks",
              whiteSpace: "pre-line",
            }}
            color="common.white"
          >
            {`Suite 445, 999 – 8th Street SW
Calgary, Alberta, T2R 1J5`}
          </Typography>
          <Typography
            sx={{
              marginTop: "1rem",
              // whiteSpaceCollapse: "preserve-breaks",
              whiteSpace: "pre-line",
            }}
            color="common.white"
          >
            <strong>Main Office:</strong> (403) 253-0600
          </Typography>
          <Typography
            sx={{
              // whiteSpaceCollapse: "preserve-breaks",
              whiteSpace: "pre-line",
            }}
            color="common.white"
          >
            <strong>Emergency & After Hours:</strong>{" "}
            <Link underline={"none"} sx={{ color: "white" }} href="tel:5878930300">
              (587) 893-0300
            </Link>
          </Typography>
          <Typography
            sx={{
              // whiteSpaceCollapse: "preserve-breaks",
              whiteSpace: "pre-line",
            }}
            color="common.white"
          >
            <strong>Email:</strong>{" "}
            <Link
              underline={"none"}
              sx={{ color: "white" }}
              href="mailto:info@riverparkproperties.ca"
            >
              info@riverparkproperties.ca
            </Link>
          </Typography>
        </Box>
        <Box sx={{ gridArea: { xs: null, md: "links" }, marginTop: { xs: "2rem", sm: "initial" } }}>
          <Typography
            sx={{
              fontWeight: "600",
            }}
            color="common.white"
          >
            EXPLORE RIVERPARK
          </Typography>
          {[
            { name: "Our Story", url: "/our-story" },
            { name: "Our Service", url: "/our-services" },
            { name: "Our Team", url: "/our-team" },
            { name: "Contact Us", url: "/contact" },
            { name: "Careers", url: "/careers" },
            {
              name: "Tenant Services Portal",
              url: "https://connect.buildingengines.com/login",
              target: "riverpark",
            },
          ].map((item, index) => {
            return (
              <Typography
                key={item?.name}
                variant="body1"
                sx={{ width: "100%", lineHeight: "1.7875rem" }}
              >
                <Link
                  href={item.url}
                  target={item.target}
                  underline="none"
                  sx={{ width: "100%", color: "#fff" }}
                >
                  {item.name}
                </Link>
              </Typography>
            );
          })}
        </Box>
      </Box>
      <Box bgcolor={"rgba(10,40,60,1)"} align="center" sx={{ padding: { xs: "1rem", md: "1rem" } }}>
        <Typography
          color="common.white"
          sx={{ fontSize: { xs: 10, md: 10 }, lineHeight: { xs: 1.5, md: "initial" } }}
        >
          {`Copyright 2024 Riverpark Properties LTD.`}
        </Typography>
      </Box>
    </Container>
  );
}

const FooterLink = styled(RouterLink)`
  text-align: left;
  display: block;
  color: ${props => props.theme.palette.common.white}!important;
  &:visited {
    color: ${props => props.theme.palette.common.white}!important;
  }
`;

const Logo = styled("img")`
  padding-bottom: 2rem;
  ${props => props.theme.breakpoints.up("sm")} {
    padding-bottom: unset;
  }
`;
