import React from "react";
import styled from "@emotion/styled";

import { Event } from "./Tracking";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const handleOnClick = e => {
  Event("Social", "click", e.currentTarget.dataset.name);
};
function SocialIcons(props) {
  return (
    <StyledSocial className={props.className} style={props.style}>
      {props.children}

      {props?.facebook && (
        <a
          onClick={handleOnClick}
          data-name="Facebook"
          href={props.facebook}
          target={props.target}
          rel="noopener"
        >
          <StyledFacebookIcon iconcolor={props.iconcolor} size={props.size} />
        </a>
      )}
      {props?.twitter && (
        <a
          onClick={handleOnClick}
          data-name="Twitter"
          href={props.twitter}
          target={props.target}
          rel="noopener"
        >
          <StyledTwitterIcon iconcolor={props.iconcolor} size={props.size} />
        </a>
      )}
      {props?.instagram && (
        <a
          onClick={handleOnClick}
          data-name="Instagram"
          href={props.instagram}
          target={props.target}
          rel="noopener"
        >
          <StyledInstagramIcon iconcolor={props.iconcolor} size={props.size} />
        </a>
      )}
      {props?.linkedin && (
        <a
          onClick={handleOnClick}
          data-name="Linkein"
          href={props.linkedin}
          target={props.target}
          rel="noopener"
        >
          <StyledLinkedinIcon iconcolor={props.iconcolor} size={props.size} />
        </a>
      )}
    </StyledSocial>
  );
}
const StyledSocial = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  display: flex;
  width: 100%;
  height: 4em;
  ${props => props.theme.breakpoints.up("sm")} {
    width: auto;
    display: flex;
    height: auto;
    padding-top: 0;
    a {
      margin: 0 1em 0 0;
      background: transparent;
      &:hover {
        background: transparent;
        svg > path {
          fill: ${props => props.theme.activeColor};
        }
      }
    }
  }
  p {
    text-align: center;
  }
`;
const StyledFacebookIcon = styled(FacebookIcon)`
  color: ${props => (props.iconcolor ? props.iconcolor : props.theme.palette.primary.dark)};
  font-size: ${props => (props.size === undefined ? "2em" : props.size)} !important;
`;
const StyledTwitterIcon = styled(TwitterIcon)`
  color: ${props => (props.iconcolor ? props.iconcolor : props.theme.palette.primary.dark)};
  font-size: ${props => (props.size === undefined ? "2em" : props.size)} !important;
`;
const StyledInstagramIcon = styled(InstagramIcon)`
  color: ${props => (props.iconcolor ? props.iconcolor : props.theme.palette.primary.dark)};
  font-size: ${props => (props.size === undefined ? "2em" : props.size)} !important;
`;
const StyledLinkedinIcon = styled(LinkedInIcon)`
  color: ${props => (props.iconcolor ? props.iconcolor : props.theme.palette.primary.dark)};
  font-size: ${props => (props.size === undefined ? "2em" : props.size)} !important;
`;

export default SocialIcons;
