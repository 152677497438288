import React from "react";
import styled from "@emotion/styled";
import { Box, Grid, Typography, Container } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import WavyLines from "../assets/WavyLines.svg";
export default function TwoImages(props) {
  const { direction = "row", sx = {} } = props?.options?.wrapperStyle;
  // TODO
  // Click images to enlarge option
  // support for types
  // support for options
  return (
    <Box component={Container} sx={{ position: "relative", ...sx }} maxWidth={"xl"} disableGutters>
      {props?.options?.decorate && (
        <Waves src={WavyLines} alt="waves decoration" role="presentation" />
      )}
      <Grid
        container
        justifyContent={{ xs: "space-between", md: "center" }}
        alignItems={"stretch"}
        columnSpacing={{ xs: 0.25, md: 2 }}
        direction={direction}
      >
        {props?.Images?.data && (
          <Grid
            component={SimpleResponsivePicture}
            imageObj={props?.Images?.data[0]?.attributes}
            width={{ xs: 208, md: 650 }}
            height={{ xs: 180, md: 570 }}
            id="img-1"
            item
            xs={6.8}
          >
            {/* {props?.Images?.data && (
            <SimpleResponsivePicture
              imageObj={props?.Images?.data[0]?.attributes}
              width="650px"
              height="571px"
              id="img-1"
            />
          )} */}
          </Grid>
        )}
        {props?.Images?.data && (
          <Grid
            component={SimpleResponsivePicture}
            imageObj={props?.Images?.data[1]?.attributes}
            width={{ xs: 147, md: 460 }}
            height={{ xs: 181, md: 570 }}
            id="img-2"
            item
            xs={5}
          >
            {/* {props?.Images?.data && (
            <SimpleResponsivePicture
              imageObj={props?.Images?.data[0]?.attributes}
              width="650px"
              height="571px"
              id="img-1"
            />
          )} */}
          </Grid>
        )}
        {/* <Grid item xs={12} md={4.3}>
          {props?.Images?.data && (
            <SimpleResponsivePicture
              imageObj={props?.Images?.data[1]?.attributes}
              width="460px"
              height="571px"
              id="img-2"
            />
          )}
        </Grid> */}
        {props?.options?.caption && (
          <Typography
            variant="caption"
            color="primary.main"
            align="right"
            sx={{
              width: "100dvw",
              whiteSpaceCollapse: { xs: "break-spaces", sm: "collapse" },
              whiteSpace: "pre-line",
              lineHeight: "1.25rem",
              pt: ".5rem",
              pr: "1rem",
            }}
          >
            {props?.options?.caption}
          </Typography>
        )}
      </Grid>
    </Box>
  );
}
const Waves = styled("img")`
  position: absolute;
  display: none;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 29dvw;
    height: auto;
    display: block;
    left: -10%;
    bottom: -16%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 29dvw;
    height: auto;
    display: block;
    left: -10%;
    bottom: -11%;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 380px;
    height: auto;
    display: block;
    left: -80px;
    bottom: -80px;
  }
`;
