import React from "react";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { parseWithLinks } from "../Functions";
export default function RichTextRich({ theme, ...props }) {
  const regex = /<a (.+)>(.+)<\/a>/gm;
  const str = props.Copy;
  const subst = `<Button $1>$2</Button>`;
  const result = props.Copy.replace(regex, subst);

  return (
    <Wrapper
      theme={theme}
      sx={{ ...props?.options?.wrapperStyle?.sx }}
      // dangerouslySetInnerHTML={{ __html: result }}
    >
      {parseWithLinks(props.Copy, { textAlign: "center" })}
    </Wrapper>
  );
}
const Wrapper = styled(Container)``;
