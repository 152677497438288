import ReactGA from "react-ga4";

export const initGA = trackingID => {
  ReactGA.initialize(trackingID);
};

export const PageView = page => {
  const pageViewObject = page || {
    hitType: "pageview",
    page: window.location.pathname + window.location.search.replace(/\s+/g, "_"),
  };

  ReactGA.send(pageViewObject);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  ReactGA.event(action, {
    category: category,
    label: label,
  });
};
