import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { ThemeProvider as ThemeProviderSC } from "@emotion/react";
import smoothscroll from "smoothscroll-polyfill"; //https://github.com/iamdustan/smoothscroll
import ScrollToTop from "./components/ScrollToTop";
// Note that the unstable version unstable_createMuiStrictModeTheme is used here to get around the warning generated by strict mode.
// In version 5 of MUI this should be resolved.
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./Theme";

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Router basename={"/"}>
      <ScrollToTop />
      <ThemeProviderSC theme={theme}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ThemeProviderSC>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
