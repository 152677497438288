export default function cleanCaption(caption, defaultCaption = "No Caption") {
  // If there is no caption return a default caption
  if (!caption) {
    return defaultCaption;
  }

  // if it isn't a file name then assume it is a legit caption
  const FILENAMES_REGEX = /\.(jpe?g|png|gif|webp)$/i;
  if (caption?.search(FILENAMES_REGEX) < 0) {
    return caption;
  }
  // filter out file name garbage
  // IMPORTANT The case is handled by CSS in the component
  const regex = /\.(jpe?g|png)|render|Centron|_|-|\d+/gi;

  let tempcaption = caption
    .replace(/\.\d+x0\.jpe?g$/i, "")
    .replace(regex, " ")
    .replace(/\.(jpe?g|png)/gi, "");

  return tempcaption;
}
