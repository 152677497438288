import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Grid, Modal, Button, Fade, Box, Hidden, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import StrapiGridItemDanger from "./StrapiGridItemDanger";
import SimpleResponsivePicture from "./SimpleResponsivePicture";

export default function BioModal({ ExpandedText, buttonStyle, isSmall, ...props }) {
  const [open, setOpen] = useState(false);
  const [bio, setBio] = useState();
  useEffect(() => {
    setBio(ExpandedText);
  }, [ExpandedText]);

  const handleOpen = () => {
    setOpen(() => {
      return true;
    });
  };

  const handleClose = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  //TODO evaluate how this is going to work.
  //Use a placeholder

  if (!ExpandedText) {
    return <Box sx={{ height: 36, m: "1rem 0 2rem" }} />;
  }

  const handleScrollDown = e => {
    document.getElementById("biography")?.scrollTo({ left: 0, top: 5000, behavior: "smooth" });
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        fullWidth={true}
        sx={{ m: "1rem 0 2rem", ...buttonStyle }}
      >
        {props.Name}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Wrapper
            id="Wrapper"
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            height={"100%"}
            width={"100%"}
            onClick={handleClose}
            //   m={"0 auto"}
            //   p={8}
          >
            <Hidden smDown>
              <Close
                fontSize="large"
                onClick={handleClose}
                sx={{
                  backgroundColor: { md: "white" },
                  color: { xs: "white", md: "black" },
                  padding: { md: "1rem" },
                  position: "absolute",
                  right: { xs: 30, sm: "2svw" },
                  top: { xs: 30, sm: "calc(5dvh / 2)" },
                  zIndex: 99,
                }}
              />
            </Hidden>
            <Box
              position={"relative"}
              maxWidth={{ xs: "100dvw", md: "calc(100dvw - 16rem)" }}
              overflow={"scroll"}
              component={Grid}
              height={{ xs: "95dvh", md: "auto" }}
              container
              justifyContent={"space-around"}
              alignContent={{ xs: "flex-start", md: "normal" }}
              bgcolor={props.theme.palette.primary.main}
              p={{ xs: "4rem 2rem", md: "5rem" }}
            >
              <Hidden smUp>
                <Close
                  fontSize="large"
                  onClick={handleClose}
                  sx={{
                    backgroundColor: { md: "white" },
                    padding: { md: "1rem" },
                    position: "absolute",
                    right: { xs: 30, sm: "2svw" },
                    top: { xs: 30, sm: "calc(5dvh / 2)" },
                    zIndex: 99,
                  }}
                />
              </Hidden>

              <Grid
                item
                container
                xs={12}
                md={3}
                direction={"column"}
                alignItems={"flex-start"}
                alignContent={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Hidden smDown>
                  <SimpleResponsivePicture
                    imageObj={
                      props?.graphic?.data?.attributes || props?.graphic?.data?.attributes?.url
                    }
                    sx={{
                      width: 265,
                      height: "auto",
                      aspectRatio: "253/265",
                    }}
                    objectFit={"contain"}
                  />
                </Hidden>
                <Typography variant={"h3"} color={"secondary.main"} className="name">
                  {props.Name}
                </Typography>
                <Typography variant="body1" color={"common.white"}>
                  {props.Role}
                </Typography>
              </Grid>
              <StrapiGridItemDanger
                id="biography"
                textBlockStyle={{
                  xs: 12,
                  md: 7,
                  padding: { xs: "2rem 0", sm: "0 1rem", md: "0 1rem" },

                  overflow: "scroll",
                  // width: { xs: "100svw", sm: "90svw", md: "90svw" },
                  // height: { xs: "100dvh", sm: "90dvh", md: "fit-content" },
                }}
                Copy={bio}
              />
            </Box>
          </Wrapper>
        </Fade>
      </Modal>
    </>
  );
}
const Wrapper = styled(Grid)`
  p {
    font-size: 1rem;
    line-height: 1.7rem;

    ${props => props.theme.breakpoints.up("sm")} {
      line-height: 2rem;
    }
  }
  #biography > p {
    color: white;
    // margin: 0;
    overflow-y: scroll;
    &:first-of-type {
      margin-top: 0;
    }
  }
  #biography > h3 {
    font-size: 1.25rem;
    padding-top: 0 !important;
    line-height: normal;
    ${props => props.theme.breakpoints.up("sm")} {
      font-size: 2.1875rem;
    }
  }
  .name {
    text-transform: uppercase;
    font-weight: 600;
  }
  .MuiSvgIcon-root {
    fill: white;
    ${props => props.theme.breakpoints.up("md")} {
      fill: black;
    }
  }
`;
