import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { TextField, Grid, Typography, CircularProgress, Alert, Box } from "@mui/material";
import Button from "./Button";
import ContactFooter from "./ContactFooter";
import ReCAPTCHA from "react-google-recaptcha";

import emailjs from "@emailjs/browser";
import { Event } from "./Tracking";

function ContactForm(props) {
  //Move state into Contact.js to manage the message display.
  const [formData, setFormData] = React.useState({
    user_name: "",
    user_email: "",
    phone: "",
    message: "",
  });

  const [messageSent, setMessageSent] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFormErrorClose = () => {
    setError("");
  };

  const handleOnFormChange = e => {
    setFormData({
      ...formData,
      [Object.keys(formData).filter(key => {
        if (key === e.currentTarget.attributes.name.value) {
          return e.currentTarget.attributes.name.value;
        }
      })]: e.currentTarget.value,
    });
  };

  const handleOnFocusBlur = e => {
    e.currentTarget.reportValidity();
  };

  function sendEmail(e) {
    setMessageSent(false);
    setError("");
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_TEMPLATEID,
        e.target,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        result => {
          if (result.text === "OK") {
            Event("Contact Form", "Send Form", "Success");
            setMessageSent(() => true);
            setOpen(() => true);
            setFormData({ user_name: "", user_email: "", message: "" });
          }
        },
        error => {
          setError(error.text);
          setMessageSent(true);
          props?.event("Contact Form", "Send Form Error", error.text);
        }
      );
  }

  function onChange(value) {
    //This is used by reCaptcha. Value will be the token.
    return;
  }

  return (
    <Wrapper
      id="ContactContainer"
      p={"6.25rem 2rem"}
      component="section"
      mt={{ xs: "2rem", md: "0" }}
    >
      <Typography variant="h2" color="common.white" align="center">
        Contact Us
      </Typography>
      <Typography variant="body1" color="common.white" align="center">
        For enquiries after office hours please feel free to complete the form below.
      </Typography>
      {/* Start of Form */}
      <Grid component={"form"} className="contact-form" onSubmit={sendEmail}>
        <input type="hidden" name="sending_location" value="Contact Form" />
        <Grid
          container
          justifyContent={"space-evenly"}
          alignItems={"flex-start"}
          columnSpacing={5}
          sx={{ mt: "2rem", position: "relative", height: "max-content", zIndex: 99 }}
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={"1.5rem"}
          >
            <Grid item xs={12}>
              <TextField
                type="text"
                required
                fullWidth
                id="user_name"
                name="user_name"
                label="Name"
                value={formData.user_name}
                onChange={handleOnFormChange}
                onBlur={handleOnFocusBlur}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                value={formData.user_email}
                type="email"
                id="user_email"
                name="user_email"
                label="Email Address"
                onChange={handleOnFormChange}
                onBlur={handleOnFocusBlur}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // required
                fullWidth
                value={formData.phone}
                type="phone"
                id="user_phone"
                name="user_phone"
                label="Phone"
                onChange={handleOnFormChange}
                onBlur={handleOnFocusBlur}
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} pt={{ xs: "24px", md: 0 }}>
            <TextField
              fullWidth
              required
              multiline
              rows={8}
              value={formData.message}
              type="text"
              id="message"
              name="message"
              label="Your questions or comments"
              helperText="Can be multiple lines"
              onChange={handleOnFormChange}
              onBlur={handleOnFocusBlur}
              variant="filled"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} align="center">
          {messageSent && open && (
            <Alert onClose={handleClose} severity="success">
              <Typography variant="h4" color="textPrimary">
                Your message was sent!
              </Typography>
            </Alert>
          )}
          {error.length > 0 && (
            <Alert onClose={handleFormErrorClose} severity="error">
              <Typography variant="h5" color="textPrimary">
                ERROR! Message not sent!
              </Typography>
              <Typography variant="p" color="textPrimary">
                {error}
              </Typography>
            </Alert>
          )}
          {!open && error.length === 0 && (
            <ReCAPTCHA
              className="captcha"
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={onChange}
            />
          )}
        </Grid>

        <Grid item align="center" xs={12}>
          <Button type="submit" variant="contained">
            Send
            {!messageSent && (
              <CircularProgress
                variant="indeterminate"
                thickness={6}
                color="inherit"
                size="1rem"
                style={{ marginLeft: "1rem" }}
              />
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          {error.length > 0 && (
            <Alert onClose={handleFormErrorClose} severity="error">
              <Typography variant="h5" color="textPrimary">
                ERROR! Message not sent!
              </Typography>
              <Typography variant="p" color="textPrimary">
                {error}
              </Typography>
            </Alert>
          )}
        </Grid>
      </Grid>
      {/* End of Form */}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  background-color: #b5985e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // padding: 0 1rem;

  .contact-form {
    min-width: 320px;
    width: auto;
    margin: 0 1rem;
    ${props => props.theme.breakpoints.up("md")} {
      margin: 0 2rem;
    }
    .captcha {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .MuiFormHelperText-root {
      font-size: 0.8rem;
      color: rgb(255 255 255 / 100%);
    }
    .MuiFormLabel-root {
      font-size: 0.8rem;
      color: ${props => props.theme.palette.primary.main};
    }
    .MuiFilledInput-root {
      color: rgba(43, 63, 78, 1);
      background-color: white;
      border-radius: 0;
      font-size: 1rem;
      &:hover {
        background-color: white;
      }
    }
    // .MuiFilledInput-root {
    //   padding: 25px 12px 12px;
    // }

    .MuiFilledInput-root.Mui-focused {
      background-color: white;
    }
  }
  & > h1 {
    margin-top: 1rem;
  }
  & > p {
    text-align: center;
  }
`;
ContactForm.propTypes = {};

export default ContactForm;
