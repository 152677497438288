import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Typography, Link } from "@mui/material";
import Obfuscate from "react-obfuscate";

export default function ContactInfo() {
  // TODO get Wrapper margin from CMS options field if possible
  return (
    <Wrapper
      container
      justifyContent={"space-around"}
      component="section"
      p={{ xs: "5rem 1rem 2rem", md: "6.25rem 1rem" }}
    >
      <Grid item xs={12} md={4} align="left" style={{ whiteSpaceCollapse: "preserve" }}>
        <Typography variant="h2" align="left" mb={"2rem"}>
          Information
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 800 }}>P: </span>(403) 252-1120
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 800 }}>F: </span>(403) 252-1120
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 800 }}>E: </span>info@centrongroup.com
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 800 }}>A: </span>Blackfoot Point
          <br />
          {"    "}#104, 8826 Blackfoot Trail SE
          <br />
          {"    "}Calgary AB | T2J 3J1
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 800, marginTop: "1rem" }}>
          FOR COMMERCIAL LEASING & SALES:
        </Typography>
        <Link underline={"none"} href="mailto:Leasing@CentronGroup.com" variant="body1">
          Michael Anderson >
        </Link>
        {/* <Obfuscate
          email="Leasing@CentronGroup.com"
          style={{ whiteSpace: "collapse", textDecoration: "none" }}
        >
          <Typography variant="body1" className="name" color="common.black">
            Michael Anderson
          </Typography>
        </Obfuscate> */}
        <Typography variant="body1" style={{ fontWeight: 800, marginTop: "1rem" }}>
          FOR RETAIL LEASING & RESIDENTIAL PROJECT INQUIRIES:
        </Typography>
        <Link underline={"none"} href="mailto:aclark@centrongroup.com" variant="body1">
          Allison Clark >
        </Link>

        <Typography variant="body1" style={{ fontWeight: 800, marginTop: "1rem" }}>
          FOR CONSTRUCTION PROJECTS & TURN-KEY SERVICES:
        </Typography>
        <Link underline={"none"} href="mailto:wbenz@centrongroup.com" variant="body1">
          Wayne Benz >
        </Link>

        <Typography variant="body1" style={{ fontWeight: 800, marginTop: "1rem" }}>
          FOR ACQUISITIONS & DISPOSITIONS:
        </Typography>
        <Link underline={"none"} href="mailto:cbh@centrongroup.com" variant="body1">
          Cole Harris >
        </Link>
      </Grid>

      <Grid item xs={12} md={6} pt={{ xs: "3rem", md: 0 }}>
        <iframe
          title="clearwaterMap"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5024.39466866905!2d-114.045078!3d50.975543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717aa330cc8227%3A0xe033279361ca9997!2s8826%20Blackfoot%20Trl%20SE%2C%20Calgary%2C%20AB%20T2J%203J1%2C%20Canada!5e0!3m2!1sen!2sus!4v1708985457693!5m2!1sen!2sus"
          style={{ border: 0, width: "100%", height: "auto", aspectRatio: "600/450" }}
          allowfullscreen="true"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Grid>
    </Wrapper>
  );
}
const Wrapper = styled(Grid)``;
