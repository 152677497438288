import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Container, Box, Grid } from "@mui/material";

export default function Careers(props) {
  const [EmbeddedHTML, setEmbeddedHTML] = useState();

  const { embedStyle } = props?.options;
  const { style: wrapperStylesStyle } = props.options.wrapperStyle;
  const wrapperProps = { ...props.options.wrapperStyle };
  delete wrapperProps.style;

  const CAREERSDATA = props.data.PageContent.filter(
    (item, index) => item.__component === "content.careers"
  )[0];

  const myRequest = new Request(CAREERSDATA?.Endpoint);
  const myInit = {
    method: "GET",
    // headers: myHeaders,
    // mode: "cors",
    // cache: "default",
  };

  fetch(myRequest, myInit)
    .then(async response => response.body)
    .then(rb => {
      const reader = rb.getReader();

      return new ReadableStream({
        start(controller) {
          // The following function handles each data chunk
          function push() {
            // "done" is a Boolean and value a "Uint8Array"
            reader.read().then(({ done, value }) => {
              // If there is no more data to read
              if (done) {
                controller.close();
                return;
              }
              // Get the data and send it to the browser via the controller
              controller.enqueue(value);
              // Check chunks by logging to the console

              push();
            });
          }

          push();
        },
      });
    })
    .then(stream =>
      // Respond with our stream
      new Response(stream, { headers: { "Content-Type": "text/html" } }).text()
    )
    .then(result => {
      // Do things with result
      // var modifiedHtmlContent = htmlContent.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"((?:\s+[^>]+)*)>/gi, '<a href="$1"$2 target="Centron">');

      setEmbeddedHTML(
        result.replace(
          /<a\s+(?:[^>]*?\s+)?href="([^"]*)"((?:\s+[^>]+)*)>/gi,
          '<a href="$1"$2 target="riverpark">'
        )
      );
    });

  return (
    <Wrapper
      component="section"
      WrapperStyle={wrapperStylesStyle}
      sx={wrapperStylesStyle}
      {...wrapperProps}
      disableGutters={
        typeof wrapperProps?.disableGutters === "boolean"
          ? wrapperProps?.disableGutters
          : undefined || true
      }
      maxWidth={wrapperProps?.maxWidth || false}
    >
      <Grid container columnSpacing={5} justifyContent={"space-between"}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: { xs: "center", md: "left" } }}
          dangerouslySetInnerHTML={{ __html: CAREERSDATA.Copy }}
        />

        <EmbedWrapper
          component={Grid}
          item
          xs={12}
          md={5}
          sx={{ ...embedStyle }}
          dangerouslySetInnerHTML={{ __html: EmbeddedHTML }}
          // dangerouslySetInnerHTML={{ __html: purify.sanitize(props?.EmbedCode) }}
        />
      </Grid>
    </Wrapper>
  );
}
// ${props => {
//   return { ...props.options.textBlockStyle };
// }}
const Wrapper = styled(({ WrapperStyle, ...props }) => <Container {...props} />)`
  ${props => {
    return { ...props.WrapperStyle };
  }}
`;
// const Wrapper = styled(({ WrapperStyle, children, ...props }) => (Container, props, children))`
//   ${props => {
//     return { ...props.WrapperStyle };
//   }}
// `;

// const Button = styled(({ backgroundColor, children, ...props }) => e("button", props, children))`
//   background-color: ${props => props.backgroundColor};
// `;

const EmbedWrapper = styled(Box)`
  ${props => {
    return { ...props.embedStyle };
  }}
  .BambooHR-ATS-board {
    text-align: left;
    & > h2 {
      display: none;
    }
    .BambooHR-ATS-Department-Header {
      font-weight: 800;
      font-size: larger;
    }
    ul.BambooHR-ATS-Department-List,
    ul.BambooHR-ATS-Jobs-List {
      list-style-type: none;
      padding: 0;
    }
    .BambooHR-ATS-Location {
      margin-left: 8px;
    }
    .BambooHR-ATS-Department-Item {
      margin-bottom: 1rem;
    }
  }
`;
