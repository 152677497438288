import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Container, Typography } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";

import { addClassToLinksInParagraphs, parseWithLinks, removeCommentAttributes } from "../Functions";

export default function FullWidthImage(props) {
  const wrapperStyle = removeCommentAttributes(props?.options?.wrapperStyle) || {};
  const [isIos, setisIos] = useState();
  const imageBlockStyle = props?.options?.imageBlockStyle || {};
  useEffect(() => {
    setisIos(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  }, []);

  let result = props.Content;
  if (props?.options?.useButtonForLink) {
    result = addClassToLinksInParagraphs(result, "button", "_blank");
  }
  const makeParallax = (props?.options?.makeParallax && !isIos) || false; // ios doesn't support backgroundAttachment
  let featureCSS = "";
  const imageUrl =
    props?.Image?.data?.attributes.formats?.large?.url ||
    props?.Image?.data?.attributes.formats?.medium?.url ||
    props?.Image?.data?.attributes.formats?.small?.url ||
    props?.Image?.data?.attributes.url;

  if (makeParallax) {
    featureCSS = {
      position: "relative",
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: 500,
      backgroundImage: `url(${imageUrl})`,
    };
  }

  return (
    <Container
      disableGutters
      {...wrapperStyle}
      sx={{ ...featureCSS }}
      maxWidth={wrapperStyle?.maxWidth || false}
      style={{ position: "relative" }}
    >
      {/* TODO use parseWithLinks here */}
      {props?.Content && (
        <ContentBlock className="content-block">{parseWithLinks(result)}</ContentBlock>
      )}

      {!makeParallax && (
        <SimpleResponsivePicture
          imageObj={props?.Image?.data?.attributes}
          sx={{ ...imageBlockStyle?.sx }}
        />
      )}

      {props?.Image?.data?.attributes?.caption && (
        <Typography variant="caption" display="block" gutterBottom align="right">
          {props?.Image?.data?.attributes?.caption}
        </Typography>
      )}
    </Container>
  );
}
const ContentBlock = styled("div")`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
    width: 100%;
  }

  color: white;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
