import React from "react";
import styled from "@emotion/styled";
import { Grid, Container, Typography, Box, useMediaQuery } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import { parseWithLinks } from "../Functions";
import purify from "dompurify";
// import ResponsivePicture from "./ResponsivePicture";
//TODO fix line below image with an explicit height or crop images to fit space exactly. Might have to rethink the layout.
export default function Hero2({ Copy, theme, ...props }) {
  const isPhone = useMediaQuery(theme?.breakpoints?.down("sm"));

  // let HeroImage = imageObj[0]?.attributes;

  // if (isPhone) {
  //   HeroImage = imageObj[1]?.attributes;
  // }
  //TODO should use regex and ignore case
  if (Copy === "<p>our community</p>" && isPhone) {
    Copy = "<p>community</p>";
  }
  return (
    <Container maxWidth="xl" disableGutters>
      <Grid
        container
        flexDirection={isPhone ? "column-reverse" : "row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ position: "relative", height: 511 }}
      >
        <SimpleResponsivePicture imageObj={props?.imageObj} sx={{ height: 511, width: "100dvw" }} />
        <TextWrapper
          container
          justifyContent="center"
          alignItems="center"
          // dangerouslySetInnerHTML={{ __html: Copy }}
        >
          {parseWithLinks(Copy)}
        </TextWrapper>
      </Grid>
    </Container>
  );
}

const TextWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  position: absolute;
  color: ${props => props.theme.palette.common.white};

  h1 {
    text-align: center;
    // white-space: pre;
    max-width: 90dvw;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 69%);
    ${props => props.theme.breakpoints.up("sm")} {
      // white-space: unset;
    }
  }
  h2 {
    color: ${props => props.theme.palette.common.white};
  }
`;
