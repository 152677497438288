import React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import purify from "dompurify";
import { parseWithLinks } from "../Functions";
import { ButtonStyles } from "../ButtonStyles";
export default function StrapiGridItemDanger({
  textBlockStyle,
  Copy,
  color,
  sx,
  useButtonsForLinks,
  ...props
}) {
  // const { xs = 12, md, align, sx, style } = textBlockStyle || null;

  return (
    <Wrapper
      {...(textBlockStyle || {})}
      sx={sx}
      onClick={props.onClick}
      id={props.id}
      color={color}
      buttonStyles={ButtonStyles}
      item
    >
      {parseWithLinks(Copy, { useButtonsForLinks })}
    </Wrapper>
  );
}
const Wrapper = styled(({ buttonStyles, innerStyles, ...props }) => <Grid {...props} />)`
  ${props => {
    return props.buttonStyles;
  }}
  li {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    max-width: 1200px;
    letter-spacing: 0.64px;
    line-height: 2;
    font-size: 14px;
  }
  Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: ${props => props.theme.palette.primary.dark};
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 0.5rem 2.5rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: 65px;
    text-transform: uppercase;
    border-radius: 200px;
    border: none;
    &:hover {
      text-decoration: none;
      background-color: ${props => props.theme.palette.primary.main};
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
  ${props => {
    return { ...props?.innerStyles };
  }}
`;
