import React, { useEffect, useRef } from "react";
import { Typography, Grid, Button, Box, useMediaQuery } from "@mui/material";
import { parseWithLinks } from "../Functions";
import styled from "@emotion/styled";

import StrapiGrid from "./StrapiGrid";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import purify from "dompurify";
// import Welcome from "../assets/welcome.svg";
import VideoPoster from "../assets/VideoPoster.jpg";
export default function Hero(props) {
  //TODO Figure out how to get the image on top on phone. reversing direction doesn't work???
  const isSmall = useMediaQuery(props.theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery(props.theme.breakpoints.only("xs"));
  const videoRef = useRef(null);

  useEffect(() => {
    // if (videoRef.current && !props?.isTouch) {
    videoRef.current.playsInline = true;
    videoRef.current.play();
    // }
  }, []);

  const handleOnTouchPlay = () => {
    videoRef.current.playsInline = true;

    videoRef.current.play();
  };

  return (
    <Wrapper
      options={{
        alignContent: "center",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        sx: {
          // padding: { xs: "0", sm: "0 4rem", md: "0" },
          // height: { xs: 676, sm: 465, md: 600, lg: 704 },
        },
      }}
    >
      {isSmall && (
        <Grid
          xs={12}
          md={12}
          item
          container
          sx={{ position: "relative" }}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              position: "absolute",
              color: "white",
              zIndex: 99,
              padding: { xs: "0 1rem" },
            }}
            onTouchStart={handleOnTouchPlay}
          >
            {parseWithLinks(props?.HeroCopy)}
          </Box>
          {props?.Image?.data[0]?.attributes?.ext !== ".mp4" && (
            <SimpleResponsivePicture
              imageObj={props?.Image?.data[0]?.attributes || props?.Image?.data[0]?.attributes?.url}
              sx={{
                height: "calc(100dvh - 4rem)",
                objectFit: "cover",
                objectPosition: "-370px center",
              }}
              objectFit={"contain"}
            />
          )}
          {props?.Image?.data[0]?.attributes?.ext === ".mp4" && (
            <VideoWrapper ref={videoRef} loop autoplay muted poster={VideoPoster}>
              <source src={props?.Image?.data[0]?.attributes?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoWrapper>
          )}
        </Grid>
      )}
      {/* <Grid item xs={12} md={4} sx={{ p: { xs: "1rem", md: 0 } }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: purify
              .sanitize(
                props?.HeroCopy.replace(/\<pre\>\<code class=\"language-html\"\>\&lt\;/g, "<")
              )
              .replace(/\/\&gt\;\<\/code\>\<\/pre\>/g, "/>"),
          }}
        />
      </Grid> */}
      {!isSmall && (
        <Grid
          xs={12}
          md={12}
          item
          container
          sx={{ position: "relative" }}
          justifyContent={"flex-start"}
          alignContent={"center"}
          alignItems={"flex-end"}
        >
          {props?.Image?.data[0]?.attributes?.ext !== ".mp4" && (
            <SimpleResponsivePicture
              imageObj={props?.Image?.data[0]?.attributes || props?.Image?.data[0]?.attributes?.url}
              sx={{
                objectFit: "contain",
                objectPosition: "left center",
              }}
              objectFit={"contain"}
            />
          )}

          {props?.Image?.data[0]?.attributes?.ext === ".mp4" && (
            <VideoWrapper ref={videoRef} loop autoplay height="auto" muted>
              <source src={props?.Image?.data[0]?.attributes?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoWrapper>
          )}

          <Box
            sx={{
              position: "absolute",
              color: "white",
              paddingLeft: { xs: "7.6rem" },
              paddingBottom: { xs: "6rem" },
            }}
          >
            {parseWithLinks(props?.HeroCopy)}
          </Box>
        </Grid>
      )}
    </Wrapper>
  );
}
const VideoWrapper = styled("video")`
  object-fit: cover;
  width: 100dvw;
  height: 511px;
  ${props => props.theme.breakpoints.up("md")} {
    width: 100vw;
    height: auto;
  }
`;

const Wrapper = styled(StrapiGrid)`
  p {
    color: white;
  }

  .MuiTypography-body1 {
    font-size: 1.5625rem;
    width: 100%;
    color: white;
    text-align: left;
    ${props => props.theme.breakpoints.up("sm")} {
      // width: 779px;
      white-space-collapse: preserve-breaks;
    }

    ${props => props.theme.breakpoints.up("md")} {
      width: 779px;
      white-space-collapse: preserve-breaks;
    }
  }

  .MuiTypography-h1 {
    white-space-collapse: preserve-breaks;
    text-align: left;
    line-height: 3rem;
    font-weight: 600;
    margin-bottom: 1rem !important;
    ${props => props.theme.breakpoints.up("md")} {
      line-height: 4.5rem;
    }
  }

  .MuiButton-contained {
    background-color: ${props => props.theme.palette.common.white};
    color: ${props => props.theme.palette.common.black};
    font-size: 0.75rem;
    margin-top: 2rem;
    padding: 0 1.5rem;
    width: max-content;
    height: 65px;
    text-transform: uppercase;
    border-radius: 200px;
    border: none;
    font-weight: normal;

    &:after {
      content: unset;
    }

    &:hover {
      color: ${props => props.theme.palette.common.white};
      background-color: rgb(55, 147, 155);
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    ${props => props.theme.breakpoints.up("md")} {
      font-size: initial;
      padding: 0.5rem 2.5rem;
    }
  }
`;
