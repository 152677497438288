export default function convertColor(color, palette) {
  if (color === "Primary") {
    return palette.primary.main;
  }
  if (color === "Secondary") {
    return palette.secondary.main;
  }
  if (color === "PrimaryLight") {
    return palette.primary.light;
  }
  if (color === "SecondaryLight") {
    return palette.secondary.light;
  }
  if (color === "PrimaryDark") {
    return palette.primary.dark;
  }
  if (color === "SecondaryDark") {
    return palette.secondary.dark;
  }

  return palette.common.white;
}
