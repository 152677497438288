import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

function Video() {
  return (
    <Box
      sx={{
        padding: { xs: "1rem", sm: "1.5rem", md: "2.25rem", lg: "5rem" },
        bgcolor: "rgba(233,242,240,1)",
      }}
    >
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <div
            className="wistia_embed wistia_async_f6rew5okdo videoFoam=true"
            style={{ height: "100%", position: "relative", width: "100%" }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Video;
