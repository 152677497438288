import React from "react";
import { Box, Container, Grid } from "@mui/material";

import StrapiGrid from "./StrapiGrid";
import Description from "./Description";
import SimpleResponsivePicture from "./SimpleResponsivePicture";

import StrapiGridItemDanger from "./StrapiGridItemDanger";

export default function ServicesAndSolutions(props) {
  return (
    <Box component={Container} sx={{ ...props?.options?.wrapperStyle }}>
      <Description Copy={props?.Description?.Copy} />
      <StrapiGrid options={props?.options?.layout}>
        {props?.Service &&
          props?.Service.map((item, index, array) => {
            return (
              <Grid
                key={item.Name}
                container
                item
                md={2.45}
                xs={12}
                direction="column"
                justifyContent={"center"}
                alignContent={"flex-start"}
                sx={{ mt: { xs: "4rem", md: "2rem" } }}
              >
                <Grid item>
                  <SimpleResponsivePicture
                    imageObj={item.graphic.data.attributes}
                    width="75px"
                    height="auto"
                  />
                </Grid>
                <StrapiGridItemDanger
                  Copy={item.ExpandedText}
                  textBlockStyle={{
                    innerStyles: {
                      textAlign: "center",
                      p: { fontSize: ".6875rem", textAlign: "center" },
                    },
                  }}
                />
              </Grid>
            );
          })}
      </StrapiGrid>
    </Box>
  );
}
