import React, { useState, useEffect } from "react";
import { Typography, Container } from "@mui/material";
import { getStrapiContent } from "../Functions";
import NewsSection from "./NewsSection";
import { Outlet } from "react-router";

export default function NewsListingComponent({
  handleNewsClick,
  isSmall,
  isXsmall,
  isTouch,
  ...props
}) {
  const [NewsData, setNewsData] = useState();

  const contentParameters = [
    {
      path: "/api/news-page/",
      setter: setNewsData,
      data: NewsData,
      localStorageVariableName: "centron-news",
    },
  ];

  useEffect(() => {
    contentParameters.forEach(({ path, setter, localStorageVariableName }) => {
      getStrapiContent(path, setter, localStorageVariableName);
    });
  }, []);

  return (
    <Container maxWidth="lg" disableGutters={isSmall || isXsmall ? false : true}>
      {/* <Typography variant="h2">{props?.Title}</Typography> */}
      {NewsData && (
        <NewsSection
          handleNewsClick={handleNewsClick}
          newsData={
            NewsData?.PageContent?.filter((item, index) => {
              return item.__component === "content.news-section";
            })[0]?.NewsItems
          }
          {...props}
        />
      )}
      <Outlet />
    </Container>
  );
}
