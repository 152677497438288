import React, { useState, useEffect } from "react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Typography, useTheme } from "@mui/material";

export default function RenderJsonBlocks(InputText) {
  const theme = useTheme();
  const [TextFieldContent, setTextFieldContent] = useState([{}]);

  useEffect(() => {
    setTextFieldContent(() => {
      return InputText?.TextField;
    });
  }, [InputText?.TextField]);

  return (
    <BlocksRenderer
      content={TextFieldContent || [""]}
      blocks={{
        // You can use the default components to set class names...
        list: ({ children }) => (
          <ul style={{ color: theme.palette.common.black }}>
            <Typography variant="body1" color={theme.palette.common.black}>
              {children}
            </Typography>
          </ul>
        ),
        paragraph: ({ children }) => <Typography variant="body1">{children}</Typography>,
        // ...or point to a design system
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return <Typography variant="h1">{children}</Typography>;

            case 2:
              return <Typography variant="h2">{children}</Typography>;
            case 3:
              return <Typography variant="h3">{children}</Typography>;
            case 4:
              return <Typography variant="h4">{children}</Typography>;
            case 5:
              return <Typography variant="h5">{children}</Typography>;
            case 6:
              return <Typography variant="h6">{children}</Typography>;
            default:
              return <Typography variant="h1">{children}</Typography>;
          }
        },
        // For links, you may want to use the component from your router or framework
        // link: ({ children, url }) => <Link to={url}>{children}</Link>,
      }}
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => <span className="italic">{children}</span>,
      }}
    />
  );
}
