import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { motion, useViewportScroll } from "framer-motion";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  ButtonGroup,
  Button,
  Container,
  AppBar,
  Toolbar,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import CustomButton from "./Button";
import { Event } from "./Tracking";
import LogoSVG from "../assets/navLogo.svg";

function MainNavigation(props) {
  const theme = useTheme();

  const mounted = useRef(false);
  const [progress, setProgress] = useState(0);
  const { setIsModalOpen, scrollToTop } = props;

  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  if (mounted.current) {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
  }

  return (
    <AppBar position="fixed" elevation={0} sx={{ bgcolor: theme.palette.common.white }}>
      <Box bgcolor={theme.palette.common.white}>
        <motion.div
          id="motionPath"
          style={{
            position: "relative",
            backgroundColor: theme.palette.secondary.main,
            opacity: `${progress + 10}%`,
            height: 3,
            borderRight: `5px solid ${theme.palette.primary.red}`,
            zIndex: 999,
            width: `${progress}%`,
          }}
        />
      </Box>
      <Container maxWidth="xl" disableGutters>
        <Toolbar role="toolbar" disableGutters>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={"auto"}>
              <Button
                component={NavLink}
                to="/"
                onClick={scrollToTop}
                style={{ borderRight: "none" }}
                title="Riverpark Properties"
                tabIndex={0}
                sx={{ "&.active": { borderBottom: "none!important" } }}
              >
                <Logo alt="Logo" src={LogoSVG} loading="lazy" />
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              {/* <ButtonWrapper variant="text" onClick={scrollToTop} aria-label="Menu"> */}
              {props?.contentParameters?.map((item, index) => {
                return (
                  item.hasOwnProperty("path") &&
                  item.path !== "/" && (
                    <Button
                      //TODO className doesn't like to be a function
                      key={`${item.name}${index}`}
                      component={item.navComponent}
                      to={item.link}
                      alt={item.altText}
                      aria-controls="navigation"
                      tabIndex={0}
                      variant="text"
                      onClick={scrollToTop}
                      sx={{
                        paddingLeft: ".6rem",
                        paddingRight: ".6rem",
                        // fontSize: ".75rem",
                        // maxWidth: 135,
                        textAlign: "center",
                      }}
                      size="small"
                    >
                      {item.name}
                    </Button>
                  )
                );
              })}
              {/* </ButtonWrapper> */}
              <Button
                variant="contained"
                href="https://connect.buildingengines.com/login"
                target="riverpark"
                sx={{ marginLeft: "2rem" }}
              >
                Tenant Services Portal
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const Logo = styled("img")`
  width: 100%;
  height: auto;
  @media (min-width: 480px) {
    margin: 1em 0;
  }
`;
MainNavigation.propTypes = { scrollToTop: PropTypes.func };

export default MainNavigation;
