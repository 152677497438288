import React, { useState } from "react";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getContrastRatio } from "@mui/material/styles";

export default function Palette(props) {
  const [palette, setPalette] = useState("primary");
  const [swatches, setSwatches] = useState([]);
  const theme = useTheme();
  const PaletteArray = Object.keys(theme.palette);

  const handleOnPaletteChange = event => {
    setPalette(() => {
      return event.target.value;
    });
    setSwatches(() => {
      return Object.keys(theme.palette[event.target.value]);
    });
  };
  const PaletteSelector = () => {
    return (
      <FormControl mt={5} fullWidth>
        <InputLabel id="PaletteSelect">Palette</InputLabel>
        <Select
          labelId="PaletteSelect"
          id="PaletteSelect"
          value={palette}
          label="Palette"
          defaultValue={[]}
          onChange={handleOnPaletteChange}
        >
          {PaletteArray.map((key, index) => {
            if (typeof theme.palette[key] !== "object") {
              return;
            }
            return (
              <MenuItem key={key} value={key.toString()}>
                {key.toString()}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };
  return (
    <Container style={{ margin: "10rem 0 4rem" }}>
      <PaletteSelector />
      <Grid container>
        {swatches.map((swatch, index) => {
          const TextColor = () => {
            if (typeof theme.palette[palette][swatch] === "number") {
              return;
            }
            return getContrastRatio(`${theme.palette[palette][swatch]}`, "#fff") < 2
              ? "common.black"
              : "common.white";
          };

          return (
            <Box
              component={Grid}
              item
              xs={2}
              m={1}
              p={1}
              bgcolor={`${palette}.${swatch}`}
              height="75px"
              key={swatch}
            >
              <Typography color={TextColor}>{`${palette}.${swatch}`}</Typography>
              <Typography color={TextColor}>{`${theme.palette[palette][swatch]}`}</Typography>
            </Box>
          );
        })}
        <Box>
          <Typography variant="h1">Riverpark Properties</Typography>
          <Typography variant="h1" gutterBottom>
            h1. We’re Better Together
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. We Care
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Not your average...
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
            tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
            tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
            numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="body2" gutterBottom>
            body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
            numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            button text
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            caption text
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            overline text
          </Typography>
          <Typography variant="aside" display="block" gutterBottom>
            overline text
          </Typography>
          <Button variant="contained">primary</Button>
          <Button variant="contained" color="secondary">
            secondary
          </Button>
          <Button variant="outlined" color="primary">
            primary
          </Button>
          <Button variant="outlined" color="secondary">
            secondary
          </Button>
          <Button variant="text" color="primary">
            primary
          </Button>
          <Button variant="text" color="secondary">
            secondary
          </Button>
        </Box>
      </Grid>
    </Container>
  );
}
