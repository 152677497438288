import React from "react";
import styled from "@emotion/styled";
import Logo from "../assets/navLogo.svg";

export default function NewsPlaceholderImage(props) {
  return (
    <Wrapper alignItems={props?.alignItems || "center"}>
      <ImageWrapper src={Logo} alt="Centron Group" />
    </Wrapper>
  );
}

const Wrapper = styled(({ alignItems, ...props }) => <div {...props} />)`
  height: auto;
  width: 100%;
  aspect-ratio: 16/9;
  display: grid;
  justify-content: center;
  align-items: ${props => props.alignItems};
`;

const ImageWrapper = styled("img")`
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
`;
